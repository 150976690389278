@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-moz-selection {
  background: transparent;
}

::selection {
  background: transparent;
} */

/* -------------
Sidebar Nav
----------------*/
.sidebar-nav {
  position: fixed;
  background-color: #ffffff;
  height: auto;
  font-weight: 400;
  font-size: 1.2em;
  /* overflow: auto; */
  z-index: -10;
  /* overflow: hidden; */
  -webkit-overflow-scrolling: touch;
  border-radius: 16px;
  width: 80%;
}

.sidebar-nav ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.sidebar-nav ul li {
  margin-left: 0;
  padding-left: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  height: 60px;
}

.sidebar-nav ul li a {
  color: rgb(0 0 0 / 90%);
  font-size: 0.75em;
  padding: 1.05em 1em;
  position: relative;
  display: block;
}

.sidebar-nav ul li div {
  color: rgb(0 0 0 / 90%);
  font-size: 0.75em;
  padding: 1.05em 1em;
  position: relative;
}

.sidebar-nav ul ul a {
  color: rgba(0, 0, 0, 0.9);
  font-size: 0.75em;
  padding: 1.05em 1em;
  position: relative;
  display: flex;
  margin-top: 7px;
}

.sidebar-nav ul ul a span {
  position: relative;
  top: 20px;
}

.sidebar-nav ul li a:hover {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
  background-color: rgb(245 245 245 / 90%);
}

/* 
.sidebar-nav ul li div:hover {
    background-color: rgb(245 245 245 / 90%);
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
    border-radius:14px;
} */

.sidebar-nav ul ul a:hover {
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.sidebar-nav ul li img {
  padding-right: 0.5em;
  width: 33px;
  display: inline;
  vertical-align: middle;
  /* filter: brightness(1) invert(1); */
}

.sidebar-nav ul ul img {
  padding-right: 0.5em;
  width: 30px;
  display: inline;
  vertical-align: middle;
  filter: brightness(1) invert(1);
}

.sidebar-nav > ul > li > a:after {
  content: "Ã¯â€žÂ¥";
  font-family: ionicons;
  font-size: 0.5em;
  width: 10px;
  color: #fff;
  position: absolute;
  right: 0.75em;
  top: 45%;
}

.sidebar-nav > ul > li > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-nav ul > li .nav-flyout {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* .sidebar-nav ul>li:hover .nav-flyout {
    position: absolute;
    background-color: #ffffff;
    z-index: 9;
    left: 2.5em;
    top: 0;
    height: 100vh;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;
} */

.sidebar-nav .nav-flyout a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.sidebar-nav ul > li .nav-flyout {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  padding-top: 10px;
  padding-right: 10px;
  opacity: 0;
}

.hidden {
  visibility: hidden !important;
}

body > section > aside > nav > ul > li > ul > li > a > i {
  display: none;
}

body > section > aside > nav > ul > li > a > span {
  margin-left: 6px;
}

.menuDetailTitle {
  font-size: 13px;
  margin-left: 15px;
  font-weight: 700;
}

.menuDetailLine {
  width: 20px;
  height: 1.5px;
  background-color: black;
  padding: 0 !important;
  position: relative;
  top: -4px;
  display: inline-block;
}

input[type="radio"] {
  display: none;
}

input[type="radio"][checked="true"] + label + .nav-flyout {
  left: 2.5em;
  position: absolute;
  background-color: #ffffff;
  z-index: 9;
  top: 0;
  height: 100%;
  animation-fill-mode: forwards;
  /* animation-name: openMenu; */
  animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(94%);
  opacity: 1;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

input[type="radio"][checked="false"] + label + .nav-flyout {
  animation-fill-mode: forwards;
  /* animation-name: closeMenu; */
  animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 0;
}

input.switch {
  /* position: relative; */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 35px !important;
  height: 20px;
  border-radius: 30px;
  cursor: pointer;
  background: #ccc;
  transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
  transition-delay: 0.1s, 0s;
}

.switchAyar {
  position: absolute;
  right: 10px;
}

input.switch:focus {
  border: 0;
  outline: 0;
}

input.switch:checked {
  transition-delay: 0s, 0s;
  box-shadow: inset 0 0 0 2.62857em #000 !important;
  padding-left: 15px;
}

input.switch:before {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 3px;
  background-color: #fff;
  border-radius: 2.1em;
  transition: all 0.1s ease 0.1s;
}

input.switch:before,
input.switch:after {
  content: "";
  display: block;
}

input.switch:active::before {
  transition: all 0.1s ease 0.05s;
}

.categoriesContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.category {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 20px;
}

.slide-bck-center {
  animation: slide-bck-center 0.45s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@keyframes slide-bck-center {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translateZ(-400px);
  }
}

.categoriesButton {
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: #fff;
  padding: 10px 20px;
  border: 2px solid #000;
  color: #000;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  transition: background 0.25s linear;
  -moz-transition: background 0.25s linear;
  /* Firefox 4 */
  -webkit-transition: background 0.25s linear;
  /* Safari and Chrome */
  -o-transition: background 0.25s linear;
  /* Opera */
  -ms-transition: background 0.25s linear;
  /* Explorer 10 */
}

.categoriesButton:hover {
  background-color: #000;
  color: white;
  cursor: pointer;
}

.categoriesButton:hover svg {
  fill: #fff;
}

.activeCategories {
  border-width: 5px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  user-select: none;
}

.checkbox-group > * {
  margin: 0.5rem 0.5rem;
}

.checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}

.checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-input:checked + .checkbox-tile {
  border-color: #000000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #000000;
  margin-top: 5px;
}

.checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #000000;
  border-color: #000000;
}

.checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: #000000;
  margin-top: 10px;
}

.checkbox-input:focus + .checkbox-tile {
  border-color: #000000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #000000c0;
}

.checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
  min-height: 10rem;
  border-radius: 0.5rem;
  border: 2px solid #000000;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 50%;
  top: 0.6rem;
  left: 0.6rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.checkbox-tile:hover {
  border-color: #000000;
}

.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-icon img {
  width: 2.6rem;
  height: 2.6rem;
}

.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}

.hiddenView {
  display: none;
}

.categoriesButton-2 {
  position: absolute;
  bottom: 50px;
  left: 50px;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border: 2px solid #000;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  transition: background 0.25s linear;
  -moz-transition: background 0.25s linear;
  /* Firefox 4 */
  -webkit-transition: background 0.25s linear;
  /* Safari and Chrome */
  -o-transition: background 0.25s linear;
  /* Opera */
  -ms-transition: background 0.25s linear;
  /* Explorer 10 */
}

.categoriesButton-2:hover {
  background-color: #000;
  color: white;
  cursor: pointer;
}

.categoriesButton-2 svg {
  transform: rotate(180deg) !important;
}

.categoriesButton-2:hover svg {
  fill: #fff;
  transform: rotate(180deg) !important;
}

div.label {
  background-color: white;
  border: 1px solid #888888;
  padding: 5px;
  border-radius: 3px;
  font-family: Arial, Helvetica, sans-serif;
}

/* input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 3px solid #000000;
  height: 10px;
  width: 10px;
  border-radius: 9999px;
  background: #0c0c0c;
  cursor: pointer;
  /* margin-top: -14px; */
/* box-shadow: 1px 1px 1px #f5f5f5, 0px 0px 1px #0d0d0d; 
} */

.colorSettings {
  padding: 9px !important;
  border-radius: 20px;
  position: relative;
  right: -25px;
}

.changeLightColorDiv {
  width: 250px;
  height: 250px;
  padding: 5px;
  background-color: red;
  position: absolute;
}

.tooltipContainer {
  z-index: 100;
  width: 250px;
  background-color: red;
}

.pleksiPanel {
  width: 250px;
  height: auto;
  background-color: #fff;
  margin-top: 10px;
  position: relative;
  right: 100px;
  padding: 10px;
  border: 1px solid #7c7d81a9;
  display: flex;
  transform: scale(1.2);
  border-radius: 4px;
}

.threeButton {
  margin-right: 10px;
  border: 1.4px solid #7c7d81a9;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.threeButton img {
  width: 20px;
}

.activeToggle {
  border: 2px solid #cad0d0;
}

.v--modal-box {
  border-radius: 6px;
  padding: 16px;
}

.v--modal-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.ty-modal__close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  z-index: 9;
}

.ty-modal--no-padding .v--modal-box {
  padding: 0 !important;
}

@media screen and (min-width: 1024px) {
  .v--modal-box {
    padding: 32px;
  }

  .v--modal-overlay .v--modal-box {
    max-height: 100vh;
    overflow-y: auto;
  }
}

.ty-notify {
  top: 64px;
}

@media screen and (max-width: 1023px) {
  .ty-notify {
    top: 49px;
  }
}

.ty-notify .notification-info {
  background-color: #fff;
}

.klarna-mobile-fallaback {
  margin-left: 14px;
}

.klarna-mobile-widget {
  border-top: 1px solid #d7dadb;
}

.klarna-wrapper {
  margin: -9px -14px -9px -6px;
}

.card[data-v-3d94e223] {
  padding: 24px;
  border-radius: 12px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}

.tooltip-slide-enter-active[data-v-f0ccc80c],
.tooltip-slide-leave-active[data-v-f0ccc80c] {
  transition: top 0.2s ease-out, opacity 0.2s ease-out;
}

.tooltip-slide-enter[data-v-f0ccc80c],
.tooltip-slide-leave-to[data-v-f0ccc80c] {
  opacity: 0;
  top: 35px;
}

.tooltip-slide-enter-to[data-v-f0ccc80c],
.tooltip-slide-leave[data-v-f0ccc80c] {
  opacity: 1;
  top: 30px;
}

.tylko-tabs-container[data-v-75632451] {
  position: relative;
  overflow: hidden;
  margin-top: -10px;
}

.tylko-tabs-container .shadow[data-v-75632451] {
  position: absolute;
  width: 10px;
  height: 36px;
  top: 2px;
  bottom: 0;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.tylko-tabs-container .shadow.left[data-v-75632451] {
  opacity: 0;
  left: 0;
  background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
}

.tylko-tabs-container .shadow.right[data-v-75632451] {
  opacity: 1;
  right: 0;
  background: linear-gradient(270deg, #fff 0, hsla(0, 0%, 100%, 0));
}

.tylko-tabs-wrapper[data-v-75632451] {
  display: block;
  text-align: left;
  padding-bottom: 10px;
  transform: translateY(10px);
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: auto;
  -webkit-overflow-scrolling: touch;
}

.tylko-tabs-wrapper > [data-v-75632451] {
  display: inline-block;
}

.tylko-tabs-wrapper[data-v-75632451]::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.tylko-container.visible[data-v-3743d3f0] {
  display: block;
}

.tylko-cell[data-v-090dec6a] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tylko-cell .text[data-v-090dec6a] {
  margin-bottom: 0;
}

.percentage--in-one-line[data-v-a7080f86] {
  transform: translateY(-2px);
}

.cplus-crossed-out-price[data-v-a7080f86] {
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.price-with-discount--breaked[data-v-a7080f86] {
  flex: 0 1 100%;
}

.button-with-loader[data-v-02c05f02] {
  position: relative;
}

.button-with-loader__content.loading[data-v-02c05f02] {
  opacity: 0;
}

.button-with-loader__loader[data-v-02c05f02] {
  display: flex;
  left: auto;
  top: auto;
  transform: scale(0.5);
}

.miniature[data-v-14ea26ac] {
  display: flex;
}

.mobile-canvas-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.klarna-icon {
  margin: 0 -3px -8px;
}

.klarna-desktop-wrapper--as-fallback {
  margin-top: -4px;
}

.cta-modal__point[data-v-546b9a18] {
  position: relative;
  display: flex;
  flex-direction: row;
}

.cta-modal__point .svg-column[data-v-546b9a18] {
  position: relative;
  width: 58px;
}

.cta-modal__point .svg-column svg[data-v-546b9a18] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
}

.cta-modal__point .svg-column .img-uk[data-v-546b9a18] {
  height: 28px;
  width: 28px;
}

.cta-modal__point .content-column[data-v-546b9a18] {
  width: 375px;
}

.cta-modal__point .content-column[data-v-546b9a18],
.cta-modal__point .svg-column[data-v-546b9a18] {
  display: inline-block;
}

.cta-button[data-v-546b9a18] {
  width: 190px;
  margin: 0 auto;
}

#pdp-cplus .container-cstm,
#pdp-cplus .container-cstm-fluid,
#pdp-cplus .container-cstm-fluid-box {
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box;
}

#pdp-cplus .container-cstm {
  margin: 0 auto;
  max-width: 1292px;
  position: relative;
  padding: 0 16px;
}

@media screen and (min-width: 1024px) {
  #pdp-cplus .container-cstm {
    padding: 0 74px;
  }
}

#pdp-cplus .container-cstm-fluid,
#pdp-cplus .container-cstm-fluid-box {
  padding-right: 16px;
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  #pdp-cplus .container-cstm-fluid,
  #pdp-cplus .container-cstm-fluid-box {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (min-width: 1024px) {
  #pdp-cplus .container-cstm-fluid,
  #pdp-cplus .container-cstm-fluid-box {
    padding-right: 48px;
    padding-left: 48px;
  }
}

@media screen and (min-width: 1440px) {
  #pdp-cplus .container-cstm-fluid,
  #pdp-cplus .container-cstm-fluid-box {
    padding-right: 88px;
    padding-left: 88px;
  }
}

@media screen and (min-width: 1920px) {
  #pdp-cplus .container-cstm-fluid,
  #pdp-cplus .container-cstm-fluid-box {
    padding-right: 128px;
    padding-left: 128px;
  }
}

#pdp-cplus .container-cstm-fluid-box,
#pdp-cplus .row {
  box-sizing: border-box;
}

#pdp-cplus .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  row-gap: 10px;
}

#pdp-cplus .row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

#pdp-cplus .col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

#pdp-cplus .col-xs,
#pdp-cplus .col-xs-1,
#pdp-cplus .col-xs-2,
#pdp-cplus .col-xs-3,
#pdp-cplus .col-xs-4,
#pdp-cplus .col-xs-5,
#pdp-cplus .col-xs-6,
#pdp-cplus .col-xs-7,
#pdp-cplus .col-xs-8,
#pdp-cplus .col-xs-9,
#pdp-cplus .col-xs-10,
#pdp-cplus .col-xs-11,
#pdp-cplus .col-xs-12,
#pdp-cplus .col-xs-offset-0,
#pdp-cplus .col-xs-offset-1,
#pdp-cplus .col-xs-offset-2,
#pdp-cplus .col-xs-offset-3,
#pdp-cplus .col-xs-offset-4,
#pdp-cplus .col-xs-offset-5,
#pdp-cplus .col-xs-offset-6,
#pdp-cplus .col-xs-offset-7,
#pdp-cplus .col-xs-offset-8,
#pdp-cplus .col-xs-offset-9,
#pdp-cplus .col-xs-offset-10,
#pdp-cplus .col-xs-offset-11,
#pdp-cplus .col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 8px;
  padding-left: 8px;
}

#pdp-cplus .col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

#pdp-cplus .col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

#pdp-cplus .col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

#pdp-cplus .col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

#pdp-cplus .col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

#pdp-cplus .col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

#pdp-cplus .col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

#pdp-cplus .col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

#pdp-cplus .col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

#pdp-cplus .col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

#pdp-cplus .col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

#pdp-cplus .col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

#pdp-cplus .col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

#pdp-cplus .col-xs-offset-0 {
  margin-left: 0;
}

#pdp-cplus .col-xs-offset-1 {
  margin-left: 8.33333333%;
}

#pdp-cplus .col-xs-offset-2 {
  margin-left: 16.66666667%;
}

#pdp-cplus .col-xs-offset-3 {
  margin-left: 25%;
}

#pdp-cplus .col-xs-offset-4 {
  margin-left: 33.33333333%;
}

#pdp-cplus .col-xs-offset-5 {
  margin-left: 41.66666667%;
}

#pdp-cplus .col-xs-offset-6 {
  margin-left: 50%;
}

#pdp-cplus .col-xs-offset-7 {
  margin-left: 58.33333333%;
}

#pdp-cplus .col-xs-offset-8 {
  margin-left: 66.66666667%;
}

#pdp-cplus .col-xs-offset-9 {
  margin-left: 75%;
}

#pdp-cplus .col-xs-offset-10 {
  margin-left: 83.33333333%;
}

#pdp-cplus .col-xs-offset-11 {
  margin-left: 91.66666667%;
}

#pdp-cplus .start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}

#pdp-cplus .center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

#pdp-cplus .reverse-xs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

#pdp-cplus .end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

#pdp-cplus .top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#pdp-cplus .middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#pdp-cplus .bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#pdp-cplus .around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

#pdp-cplus .between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#pdp-cplus .first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

#pdp-cplus .last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media only screen and (min-width: 48em) {
  #pdp-cplus .container {
    width: 49rem;
  }

  #pdp-cplus .col-sm,
  #pdp-cplus .col-sm-1,
  #pdp-cplus .col-sm-2,
  #pdp-cplus .col-sm-3,
  #pdp-cplus .col-sm-4,
  #pdp-cplus .col-sm-5,
  #pdp-cplus .col-sm-6,
  #pdp-cplus .col-sm-7,
  #pdp-cplus .col-sm-8,
  #pdp-cplus .col-sm-9,
  #pdp-cplus .col-sm-10,
  #pdp-cplus .col-sm-11,
  #pdp-cplus .col-sm-12,
  #pdp-cplus .col-sm-offset-0,
  #pdp-cplus .col-sm-offset-1,
  #pdp-cplus .col-sm-offset-2,
  #pdp-cplus .col-sm-offset-3,
  #pdp-cplus .col-sm-offset-4,
  #pdp-cplus .col-sm-offset-5,
  #pdp-cplus .col-sm-offset-6,
  #pdp-cplus .col-sm-offset-7,
  #pdp-cplus .col-sm-offset-8,
  #pdp-cplus .col-sm-offset-9,
  #pdp-cplus .col-sm-offset-10,
  #pdp-cplus .col-sm-offset-11,
  #pdp-cplus .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  #pdp-cplus .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  #pdp-cplus .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  #pdp-cplus .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  #pdp-cplus .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  #pdp-cplus .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  #pdp-cplus .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  #pdp-cplus .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  #pdp-cplus .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  #pdp-cplus .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  #pdp-cplus .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  #pdp-cplus .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  #pdp-cplus .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  #pdp-cplus .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  #pdp-cplus .col-sm-offset-0 {
    margin-left: 0;
  }

  #pdp-cplus .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  #pdp-cplus .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  #pdp-cplus .col-sm-offset-3 {
    margin-left: 25%;
  }

  #pdp-cplus .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  #pdp-cplus .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  #pdp-cplus .col-sm-offset-6 {
    margin-left: 50%;
  }

  #pdp-cplus .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  #pdp-cplus .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  #pdp-cplus .col-sm-offset-9 {
    margin-left: 75%;
  }

  #pdp-cplus .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  #pdp-cplus .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  #pdp-cplus .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }

  #pdp-cplus .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  #pdp-cplus .reverse-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  #pdp-cplus .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  #pdp-cplus .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  #pdp-cplus .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #pdp-cplus .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  #pdp-cplus .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  #pdp-cplus .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  #pdp-cplus .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  #pdp-cplus .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 64em) {
  #pdp-cplus .container {
    width: 65rem;
  }

  #pdp-cplus .col-md,
  #pdp-cplus .col-md-1,
  #pdp-cplus .col-md-2,
  #pdp-cplus .col-md-3,
  #pdp-cplus .col-md-4,
  #pdp-cplus .col-md-5,
  #pdp-cplus .col-md-6,
  #pdp-cplus .col-md-7,
  #pdp-cplus .col-md-8,
  #pdp-cplus .col-md-9,
  #pdp-cplus .col-md-10,
  #pdp-cplus .col-md-11,
  #pdp-cplus .col-md-12,
  #pdp-cplus .col-md-offset-0,
  #pdp-cplus .col-md-offset-1,
  #pdp-cplus .col-md-offset-2,
  #pdp-cplus .col-md-offset-3,
  #pdp-cplus .col-md-offset-4,
  #pdp-cplus .col-md-offset-5,
  #pdp-cplus .col-md-offset-6,
  #pdp-cplus .col-md-offset-7,
  #pdp-cplus .col-md-offset-8,
  #pdp-cplus .col-md-offset-9,
  #pdp-cplus .col-md-offset-10,
  #pdp-cplus .col-md-offset-11,
  #pdp-cplus .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  #pdp-cplus .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  #pdp-cplus .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  #pdp-cplus .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  #pdp-cplus .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  #pdp-cplus .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  #pdp-cplus .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  #pdp-cplus .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  #pdp-cplus .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  #pdp-cplus .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  #pdp-cplus .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  #pdp-cplus .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  #pdp-cplus .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  #pdp-cplus .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  #pdp-cplus .col-md-offset-0 {
    margin-left: 0;
  }

  #pdp-cplus .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  #pdp-cplus .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  #pdp-cplus .col-md-offset-3 {
    margin-left: 25%;
  }

  #pdp-cplus .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  #pdp-cplus .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  #pdp-cplus .col-md-offset-6 {
    margin-left: 50%;
  }

  #pdp-cplus .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  #pdp-cplus .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  #pdp-cplus .col-md-offset-9 {
    margin-left: 75%;
  }

  #pdp-cplus .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  #pdp-cplus .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  #pdp-cplus .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }

  #pdp-cplus .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  #pdp-cplus .reverse-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  #pdp-cplus .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  #pdp-cplus .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  #pdp-cplus .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #pdp-cplus .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  #pdp-cplus .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  #pdp-cplus .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  #pdp-cplus .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  #pdp-cplus .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 1441px) {
  #pdp-cplus .container {
    width: 76rem;
  }

  #pdp-cplus .col-lg,
  #pdp-cplus .col-lg-1,
  #pdp-cplus .col-lg-2,
  #pdp-cplus .col-lg-3,
  #pdp-cplus .col-lg-4,
  #pdp-cplus .col-lg-5,
  #pdp-cplus .col-lg-6,
  #pdp-cplus .col-lg-7,
  #pdp-cplus .col-lg-8,
  #pdp-cplus .col-lg-9,
  #pdp-cplus .col-lg-10,
  #pdp-cplus .col-lg-11,
  #pdp-cplus .col-lg-12,
  #pdp-cplus .col-lg-offset-0,
  #pdp-cplus .col-lg-offset-1,
  #pdp-cplus .col-lg-offset-2,
  #pdp-cplus .col-lg-offset-3,
  #pdp-cplus .col-lg-offset-4,
  #pdp-cplus .col-lg-offset-5,
  #pdp-cplus .col-lg-offset-6,
  #pdp-cplus .col-lg-offset-7,
  #pdp-cplus .col-lg-offset-8,
  #pdp-cplus .col-lg-offset-9,
  #pdp-cplus .col-lg-offset-10,
  #pdp-cplus .col-lg-offset-11,
  #pdp-cplus .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  #pdp-cplus .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  #pdp-cplus .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  #pdp-cplus .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  #pdp-cplus .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  #pdp-cplus .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  #pdp-cplus .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  #pdp-cplus .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  #pdp-cplus .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  #pdp-cplus .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  #pdp-cplus .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  #pdp-cplus .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  #pdp-cplus .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  #pdp-cplus .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  #pdp-cplus .col-lg-offset-0 {
    margin-left: 0;
  }

  #pdp-cplus .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  #pdp-cplus .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  #pdp-cplus .col-lg-offset-3 {
    margin-left: 25%;
  }

  #pdp-cplus .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  #pdp-cplus .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  #pdp-cplus .col-lg-offset-6 {
    margin-left: 50%;
  }

  #pdp-cplus .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  #pdp-cplus .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  #pdp-cplus .col-lg-offset-9 {
    margin-left: 75%;
  }

  #pdp-cplus .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  #pdp-cplus .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  #pdp-cplus .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }

  #pdp-cplus .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  #pdp-cplus .reverse-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  #pdp-cplus .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  #pdp-cplus .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  #pdp-cplus .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #pdp-cplus .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  #pdp-cplus .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  #pdp-cplus .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  #pdp-cplus .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  #pdp-cplus .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

#pdp-cplus .text-transparent {
  color: transparent;
}

#pdp-cplus .bg-transparent {
  background-color: transparent;
}

#pdp-cplus .border-transparent {
  border-color: transparent;
}

#pdp-cplus .text-currentColor {
  color: currentColor;
}

#pdp-cplus .bg-currentColor {
  background-color: currentColor;
}

#pdp-cplus .border-currentColor {
  border-color: currentColor;
}

#pdp-cplus .text-confirmation {
  color: #3cbe5a;
}

#pdp-cplus .bg-confirmation {
  background-color: #3cbe5a;
}

#pdp-cplus .border-confirmation {
  border-color: #3cbe5a;
}

#pdp-cplus .text-warning {
  color: #ffc107;
}

#pdp-cplus .bg-warning {
  background-color: #ffc107;
}

#pdp-cplus .border-warning {
  border-color: #ffc107;
}

#pdp-cplus .text-error {
  color: #ff0037;
}

#pdp-cplus .bg-error {
  background-color: #ff0037;
}

#pdp-cplus .border-error {
  border-color: #ff0037;
}

#pdp-cplus .text-orange {
  color: #0d646f;
}

#pdp-cplus .bg-orange {
  background-color: #0d646f;
}

#pdp-cplus .border-orange {
  border-color: #0d646f;
}

#pdp-cplus .text-white {
  color: #fff;
}

#pdp-cplus .bg-white {
  background-color: #fff;
}

#pdp-cplus .border-white {
  border-color: #fff;
}

#pdp-cplus .text-black {
  color: #000;
}

#pdp-cplus .bg-black {
  background-color: #000;
}

#pdp-cplus .border-black {
  border-color: #000;
}

#pdp-cplus .text-brown {
  color: #433e3a;
}

#pdp-cplus .bg-brown {
  background-color: #433e3a;
}

#pdp-cplus .border-brown {
  border-color: #433e3a;
}

#pdp-cplus .text-offblack-600 {
  color: #484444;
}

#pdp-cplus .bg-offblack-600 {
  background-color: #484444;
}

#pdp-cplus .border-offblack-600 {
  border-color: #484444;
}

#pdp-cplus .text-offblack-700 {
  color: #2f2f2f;
}

#pdp-cplus .bg-offblack-700 {
  background-color: #2f2f2f;
}

#pdp-cplus .border-offblack-700 {
  border-color: #2f2f2f;
}

#pdp-cplus .text-offblack-800 {
  color: #1f1d1d;
}

#pdp-cplus .bg-offblack-800 {
  background-color: #1f1d1d;
}

#pdp-cplus .border-offblack-800 {
  border-color: #1f1d1d;
}

#pdp-cplus .text-offblack-900 {
  color: #1a1818;
}

#pdp-cplus .bg-offblack-900 {
  background-color: #1a1818;
}

#pdp-cplus .border-offblack-900 {
  border-color: #1a1818;
}

#pdp-cplus .text-grey-600 {
  color: #edf0f0;
}

#pdp-cplus .bg-grey-600 {
  background-color: #edf0f0;
}

#pdp-cplus .border-grey-600 {
  border-color: #edf0f0;
}

#pdp-cplus .text-grey-700 {
  color: #d7dadb;
}

#pdp-cplus .bg-grey-700 {
  background-color: #d7dadb;
}

#pdp-cplus .border-grey-700 {
  border-color: #d7dadb;
}

#pdp-cplus .text-grey-800 {
  color: #cad0d0;
}

#pdp-cplus .bg-grey-800 {
  background-color: #cad0d0;
}

#pdp-cplus .border-grey-800 {
  border-color: #cad0d0;
}

#pdp-cplus .text-grey-900 {
  color: #7c7d81;
}

#pdp-cplus .bg-grey-900 {
  background-color: #7c7d81;
}

#pdp-cplus .border-grey-900 {
  border-color: #7c7d81;
}

#pdp-cplus .text-offwhite-600 {
  color: #f9f9f9;
}

#pdp-cplus .bg-offwhite-600 {
  background-color: #f9f9f9;
}

#pdp-cplus .border-offwhite-600 {
  border-color: #f9f9f9;
}

#pdp-cplus .text-offwhite-700 {
  color: #f5f5f5;
}

#pdp-cplus .bg-offwhite-700 {
  background-color: #f5f5f5;
}

#pdp-cplus .border-offwhite-700 {
  border-color: #f5f5f5;
}

#pdp-cplus .text-offwhite-800 {
  color: #fef6f6;
}

#pdp-cplus .bg-offwhite-800 {
  background-color: #fef6f6;
}

#pdp-cplus .border-offwhite-800 {
  border-color: #fef6f6;
}

#pdp-cplus .text-offwhite-900 {
  color: #f1eaea;
}

#pdp-cplus .bg-offwhite-900 {
  background-color: #f1eaea;
}

#pdp-cplus .border-offwhite-900 {
  border-color: #f1eaea;
}

#pdp-cplus .text-orange-600 {
  color: #ffebe5;
}

#pdp-cplus .bg-orange-600 {
  background-color: #ffebe5;
}

#pdp-cplus .border-orange-600 {
  border-color: #ffebe5;
}

#pdp-cplus .text-orange-700 {
  color: #ffd8cd;
}

#pdp-cplus .bg-orange-700 {
  background-color: #ffd8cd;
}

#pdp-cplus .border-orange-700 {
  border-color: #ffd8cd;
}

#pdp-cplus .text-orange-800 {
  color: #fbc3b2;
}

#pdp-cplus .bg-orange-800 {
  background-color: #fbc3b2;
}

#pdp-cplus .border-orange-800 {
  border-color: #fbc3b2;
}

#pdp-cplus .text-orange-900 {
  color: #09f;
}

#pdp-cplus .bg-orange-900 {
  background-color: #09f;
}

#pdp-cplus .border-orange-900 {
  border-color: #09f;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .sm\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .sm\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .sm\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .sm\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .sm\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .sm\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .sm\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .sm\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .sm\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .sm\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .sm\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .sm\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .sm\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .sm\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .sm\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .sm\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .sm\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .sm\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .sm\:text-white {
    color: #fff;
  }

  #pdp-cplus .sm\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .sm\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .sm\:text-black {
    color: #000;
  }

  #pdp-cplus .sm\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .sm\:border-black {
    border-color: #000;
  }

  #pdp-cplus .sm\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .sm\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .sm\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .sm\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .sm\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .sm\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .sm\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .sm\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .sm\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .sm\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .sm\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .sm\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .sm\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .sm\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .sm\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .sm\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .sm\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .sm\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .sm\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .sm\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .sm\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .sm\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .sm\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .sm\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .sm\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .sm\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .sm\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .sm\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .sm\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .sm\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .sm\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .sm\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .sm\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .sm\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .sm\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .sm\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .sm\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .sm\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .sm\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .sm\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .sm\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .sm\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .sm\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .sm\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .sm\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .sm\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .sm\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .sm\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .sm\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .sm\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .sm\:border-orange-900 {
    border-color: #09f;
  }
}

@media only screen and (min-width: 768px) {
  #pdp-cplus .md\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .md\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .md\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .md\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .md\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .md\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .md\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .md\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .md\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .md\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .md\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .md\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .md\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .md\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .md\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .md\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .md\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .md\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .md\:text-white {
    color: #fff;
  }

  #pdp-cplus .md\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .md\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .md\:text-black {
    color: #000;
  }

  #pdp-cplus .md\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .md\:border-black {
    border-color: #000;
  }

  #pdp-cplus .md\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .md\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .md\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .md\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .md\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .md\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .md\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .md\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .md\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .md\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .md\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .md\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .md\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .md\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .md\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .md\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .md\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .md\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .md\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .md\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .md\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .md\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .md\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .md\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .md\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .md\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .md\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .md\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .md\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .md\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .md\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .md\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .md\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .md\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .md\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .md\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .md\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .md\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .md\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .md\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .md\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .md\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .md\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .md\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .md\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .md\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .md\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .md\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .md\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .md\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .md\:border-orange-900 {
    border-color: #09f;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .lg\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .lg\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .lg\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .lg\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .lg\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .lg\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .lg\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .lg\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .lg\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .lg\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .lg\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .lg\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .lg\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .lg\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .lg\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .lg\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .lg\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .lg\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .lg\:text-white {
    color: #fff;
  }

  #pdp-cplus .lg\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .lg\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .lg\:text-black {
    color: #000;
  }

  #pdp-cplus .lg\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .lg\:border-black {
    border-color: #000;
  }

  #pdp-cplus .lg\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .lg\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .lg\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .lg\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .lg\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .lg\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .lg\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .lg\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .lg\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .lg\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .lg\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .lg\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .lg\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .lg\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .lg\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .lg\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .lg\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .lg\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .lg\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .lg\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .lg\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .lg\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .lg\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .lg\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .lg\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .lg\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .lg\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .lg\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .lg\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .lg\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .lg\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .lg\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .lg\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .lg\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .lg\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .lg\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .lg\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .lg\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .lg\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .lg\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .lg\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .lg\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .lg\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .lg\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .lg\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .lg\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .lg\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .lg\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .lg\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .lg\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .lg\:border-orange-900 {
    border-color: #09f;
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus .xlg\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .xlg\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .xlg\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .xlg\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .xlg\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .xlg\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .xlg\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .xlg\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .xlg\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .xlg\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .xlg\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .xlg\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .xlg\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .xlg\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .xlg\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .xlg\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .xlg\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .xlg\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .xlg\:text-white {
    color: #fff;
  }

  #pdp-cplus .xlg\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .xlg\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .xlg\:text-black {
    color: #000;
  }

  #pdp-cplus .xlg\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .xlg\:border-black {
    border-color: #000;
  }

  #pdp-cplus .xlg\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .xlg\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .xlg\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .xlg\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .xlg\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .xlg\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .xlg\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .xlg\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .xlg\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .xlg\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .xlg\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .xlg\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .xlg\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .xlg\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .xlg\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .xlg\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .xlg\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .xlg\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .xlg\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .xlg\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .xlg\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .xlg\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .xlg\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .xlg\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .xlg\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .xlg\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .xlg\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .xlg\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .xlg\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .xlg\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .xlg\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .xlg\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .xlg\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .xlg\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .xlg\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .xlg\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .xlg\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .xlg\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .xlg\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .xlg\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .xlg\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .xlg\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .xlg\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .xlg\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .xlg\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .xlg\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .xlg\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .xlg\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .xlg\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .xlg\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .xlg\:border-orange-900 {
    border-color: #09f;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .xl\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .xl\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .xl\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .xl\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .xl\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .xl\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .xl\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .xl\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .xl\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .xl\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .xl\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .xl\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .xl\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .xl\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .xl\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .xl\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .xl\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .xl\:text-white {
    color: #fff;
  }

  #pdp-cplus .xl\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .xl\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .xl\:text-black {
    color: #000;
  }

  #pdp-cplus .xl\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .xl\:border-black {
    border-color: #000;
  }

  #pdp-cplus .xl\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .xl\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .xl\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .xl\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .xl\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .xl\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .xl\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .xl\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .xl\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .xl\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .xl\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .xl\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .xl\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .xl\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .xl\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .xl\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .xl\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .xl\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .xl\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .xl\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .xl\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .xl\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .xl\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .xl\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .xl\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .xl\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .xl\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .xl\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .xl\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .xl\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .xl\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .xl\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .xl\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .xl\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .xl\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .xl\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .xl\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .xl\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .xl\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .xl\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .xl\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .xl\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .xl\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .xl\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .xl\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .xl\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .xl\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .xl\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .xl\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .xl\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .xl\:border-orange-900 {
    border-color: #09f;
  }
}

@media only screen and (min-width: 1920px) {
  #pdp-cplus .xl2\:text-transparent {
    color: transparent;
  }

  #pdp-cplus .xl2\:bg-transparent {
    background-color: transparent;
  }

  #pdp-cplus .xl2\:border-transparent {
    border-color: transparent;
  }

  #pdp-cplus .xl2\:text-currentColor {
    color: currentColor;
  }

  #pdp-cplus .xl2\:bg-currentColor {
    background-color: currentColor;
  }

  #pdp-cplus .xl2\:border-currentColor {
    border-color: currentColor;
  }

  #pdp-cplus .xl2\:text-confirmation {
    color: #3cbe5a;
  }

  #pdp-cplus .xl2\:bg-confirmation {
    background-color: #3cbe5a;
  }

  #pdp-cplus .xl2\:border-confirmation {
    border-color: #3cbe5a;
  }

  #pdp-cplus .xl2\:text-warning {
    color: #ffc107;
  }

  #pdp-cplus .xl2\:bg-warning {
    background-color: #ffc107;
  }

  #pdp-cplus .xl2\:border-warning {
    border-color: #ffc107;
  }

  #pdp-cplus .xl2\:text-error {
    color: #ff0037;
  }

  #pdp-cplus .xl2\:bg-error {
    background-color: #ff0037;
  }

  #pdp-cplus .xl2\:border-error {
    border-color: #ff0037;
  }

  #pdp-cplus .xl2\:text-orange {
    color: #0d646f;
  }

  #pdp-cplus .xl2\:bg-orange {
    background-color: #0d646f;
  }

  #pdp-cplus .xl2\:border-orange {
    border-color: #0d646f;
  }

  #pdp-cplus .xl2\:text-white {
    color: #fff;
  }

  #pdp-cplus .xl2\:bg-white {
    background-color: #fff;
  }

  #pdp-cplus .xl2\:border-white {
    border-color: #fff;
  }

  #pdp-cplus .xl2\:text-black {
    color: #000;
  }

  #pdp-cplus .xl2\:bg-black {
    background-color: #000;
  }

  #pdp-cplus .xl2\:border-black {
    border-color: #000;
  }

  #pdp-cplus .xl2\:text-brown {
    color: #433e3a;
  }

  #pdp-cplus .xl2\:bg-brown {
    background-color: #433e3a;
  }

  #pdp-cplus .xl2\:border-brown {
    border-color: #433e3a;
  }

  #pdp-cplus .xl2\:text-offblack-600 {
    color: #484444;
  }

  #pdp-cplus .xl2\:bg-offblack-600 {
    background-color: #484444;
  }

  #pdp-cplus .xl2\:border-offblack-600 {
    border-color: #484444;
  }

  #pdp-cplus .xl2\:text-offblack-700 {
    color: #2f2f2f;
  }

  #pdp-cplus .xl2\:bg-offblack-700 {
    background-color: #2f2f2f;
  }

  #pdp-cplus .xl2\:border-offblack-700 {
    border-color: #2f2f2f;
  }

  #pdp-cplus .xl2\:text-offblack-800 {
    color: #1f1d1d;
  }

  #pdp-cplus .xl2\:bg-offblack-800 {
    background-color: #1f1d1d;
  }

  #pdp-cplus .xl2\:border-offblack-800 {
    border-color: #1f1d1d;
  }

  #pdp-cplus .xl2\:text-offblack-900 {
    color: #1a1818;
  }

  #pdp-cplus .xl2\:bg-offblack-900 {
    background-color: #1a1818;
  }

  #pdp-cplus .xl2\:border-offblack-900 {
    border-color: #1a1818;
  }

  #pdp-cplus .xl2\:text-grey-600 {
    color: #edf0f0;
  }

  #pdp-cplus .xl2\:bg-grey-600 {
    background-color: #edf0f0;
  }

  #pdp-cplus .xl2\:border-grey-600 {
    border-color: #edf0f0;
  }

  #pdp-cplus .xl2\:text-grey-700 {
    color: #d7dadb;
  }

  #pdp-cplus .xl2\:bg-grey-700 {
    background-color: #d7dadb;
  }

  #pdp-cplus .xl2\:border-grey-700 {
    border-color: #d7dadb;
  }

  #pdp-cplus .xl2\:text-grey-800 {
    color: #cad0d0;
  }

  #pdp-cplus .xl2\:bg-grey-800 {
    background-color: #cad0d0;
  }

  #pdp-cplus .xl2\:border-grey-800 {
    border-color: #cad0d0;
  }

  #pdp-cplus .xl2\:text-grey-900 {
    color: #7c7d81;
  }

  #pdp-cplus .xl2\:bg-grey-900 {
    background-color: #7c7d81;
  }

  #pdp-cplus .xl2\:border-grey-900 {
    border-color: #7c7d81;
  }

  #pdp-cplus .xl2\:text-offwhite-600 {
    color: #f9f9f9;
  }

  #pdp-cplus .xl2\:bg-offwhite-600 {
    background-color: #f9f9f9;
  }

  #pdp-cplus .xl2\:border-offwhite-600 {
    border-color: #f9f9f9;
  }

  #pdp-cplus .xl2\:text-offwhite-700 {
    color: #f5f5f5;
  }

  #pdp-cplus .xl2\:bg-offwhite-700 {
    background-color: #f5f5f5;
  }

  #pdp-cplus .xl2\:border-offwhite-700 {
    border-color: #f5f5f5;
  }

  #pdp-cplus .xl2\:text-offwhite-800 {
    color: #fef6f6;
  }

  #pdp-cplus .xl2\:bg-offwhite-800 {
    background-color: #fef6f6;
  }

  #pdp-cplus .xl2\:border-offwhite-800 {
    border-color: #fef6f6;
  }

  #pdp-cplus .xl2\:text-offwhite-900 {
    color: #f1eaea;
  }

  #pdp-cplus .xl2\:bg-offwhite-900 {
    background-color: #f1eaea;
  }

  #pdp-cplus .xl2\:border-offwhite-900 {
    border-color: #f1eaea;
  }

  #pdp-cplus .xl2\:text-orange-600 {
    color: #ffebe5;
  }

  #pdp-cplus .xl2\:bg-orange-600 {
    background-color: #ffebe5;
  }

  #pdp-cplus .xl2\:border-orange-600 {
    border-color: #ffebe5;
  }

  #pdp-cplus .xl2\:text-orange-700 {
    color: #ffd8cd;
  }

  #pdp-cplus .xl2\:bg-orange-700 {
    background-color: #ffd8cd;
  }

  #pdp-cplus .xl2\:border-orange-700 {
    border-color: #ffd8cd;
  }

  #pdp-cplus .xl2\:text-orange-800 {
    color: #fbc3b2;
  }

  #pdp-cplus .xl2\:bg-orange-800 {
    background-color: #fbc3b2;
  }

  #pdp-cplus .xl2\:border-orange-800 {
    border-color: #fbc3b2;
  }

  #pdp-cplus .xl2\:text-orange-900 {
    color: #09f;
  }

  #pdp-cplus .xl2\:bg-orange-900 {
    background-color: #09f;
  }

  #pdp-cplus .xl2\:border-orange-900 {
    border-color: #09f;
  }
}

#pdp-cplus .mt-0 {
  margin-top: 0;
}

#pdp-cplus .mr-0 {
  margin-right: 0;
}

#pdp-cplus .mb-0 {
  margin-bottom: 0;
}

#pdp-cplus .ml-0 {
  margin-left: 0;
}

#pdp-cplus .-mx-0,
#pdp-cplus .mx-0 {
  margin-left: 0;
  margin-right: 0;
}

#pdp-cplus .my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

#pdp-cplus .m-0 {
  margin: 0;
}

#pdp-cplus .pt-0 {
  padding-top: 0;
}

#pdp-cplus .pr-0 {
  padding-right: 0;
}

#pdp-cplus .pb-0 {
  padding-bottom: 0;
}

#pdp-cplus .pl-0 {
  padding-left: 0;
}

#pdp-cplus .px-0 {
  padding-left: 0;
  padding-right: 0;
}

#pdp-cplus .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

#pdp-cplus .p-0 {
  padding: 0;
}

#pdp-cplus .mt-4 {
  margin-top: 4px;
}

#pdp-cplus .mr-4 {
  margin-right: 4px;
}

#pdp-cplus .mb-4 {
  margin-bottom: 4px;
}

#pdp-cplus .ml-4 {
  margin-left: 4px;
}

#pdp-cplus .mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

#pdp-cplus .-mx-4 {
  margin-left: -4px;
  margin-right: -4px;
}

#pdp-cplus .my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

#pdp-cplus .m-4 {
  margin: 4px;
}

#pdp-cplus .pt-4 {
  padding-top: 4px;
}

#pdp-cplus .pr-4 {
  padding-right: 4px;
}

#pdp-cplus .pb-4 {
  padding-bottom: 4px;
}

#pdp-cplus .pl-4 {
  padding-left: 4px;
}

#pdp-cplus .px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

#pdp-cplus .py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

#pdp-cplus .p-4 {
  padding: 4px;
}

#pdp-cplus .mt-8 {
  margin-top: 8px;
}

#pdp-cplus .mr-8 {
  margin-right: 8px;
}

#pdp-cplus .mb-8 {
  margin-bottom: 8px;
}

#pdp-cplus .ml-8 {
  margin-left: 8px;
}

#pdp-cplus .mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

#pdp-cplus .-mx-8 {
  margin-left: -8px;
  margin-right: -8px;
}

#pdp-cplus .my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

#pdp-cplus .m-8 {
  margin: 8px;
}

#pdp-cplus .pt-8 {
  padding-top: 8px;
}

#pdp-cplus .pr-8 {
  padding-right: 8px;
}

#pdp-cplus .pb-8 {
  padding-bottom: 8px;
}

#pdp-cplus .pl-8 {
  padding-left: 8px;
}

#pdp-cplus .px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

#pdp-cplus .py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

#pdp-cplus .p-8 {
  padding: 8px;
}

#pdp-cplus .mt-12 {
  margin-top: 12px;
}

#pdp-cplus .mr-12 {
  margin-right: 12px;
}

#pdp-cplus .mb-12 {
  margin-bottom: 12px;
}

#pdp-cplus .ml-12 {
  margin-left: 12px;
}

#pdp-cplus .mx-12 {
  margin-left: 12px;
  margin-right: 12px;
}

#pdp-cplus .-mx-12 {
  margin-left: -12px;
  margin-right: -12px;
}

#pdp-cplus .my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

#pdp-cplus .m-12 {
  margin: 12px;
}

#pdp-cplus .pt-12 {
  padding-top: 12px;
}

#pdp-cplus .pr-12 {
  padding-right: 12px;
}

#pdp-cplus .pb-12 {
  padding-bottom: 12px;
}

#pdp-cplus .pl-12 {
  padding-left: 12px;
}

#pdp-cplus .px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

#pdp-cplus .py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

#pdp-cplus .p-12 {
  padding: 12px;
}

#pdp-cplus .mt-16 {
  margin-top: 16px;
}

#pdp-cplus .mr-16 {
  margin-right: 16px;
}

#pdp-cplus .mb-16 {
  margin-bottom: 16px;
}

#pdp-cplus .ml-16 {
  margin-left: 16px;
}

#pdp-cplus .mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

#pdp-cplus .-mx-16 {
  margin-left: -16px;
  margin-right: -16px;
}

#pdp-cplus .my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

#pdp-cplus .m-16 {
  margin: 16px;
}

#pdp-cplus .pt-16 {
  padding-top: 16px;
}

#pdp-cplus .pr-16 {
  padding-right: 16px;
}

#pdp-cplus .pb-16 {
  padding-bottom: 16px;
}

#pdp-cplus .pl-16 {
  padding-left: 16px;
}

#pdp-cplus .px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

#pdp-cplus .py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

#pdp-cplus .p-16 {
  padding: 16px;
}

#pdp-cplus .mt-24 {
  margin-top: 24px;
}

#pdp-cplus .mr-24 {
  margin-right: 24px;
}

#pdp-cplus .mb-24 {
  margin-bottom: 24px;
}

#pdp-cplus .ml-24 {
  margin-left: 24px;
}

#pdp-cplus .mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

#pdp-cplus .-mx-24 {
  margin-left: -24px;
  margin-right: -24px;
}

#pdp-cplus .my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

#pdp-cplus .m-24 {
  margin: 24px;
}

#pdp-cplus .pt-24 {
  padding-top: 24px;
}

#pdp-cplus .pr-24 {
  padding-right: 24px;
}

#pdp-cplus .pb-24 {
  padding-bottom: 24px;
}

#pdp-cplus .pl-24 {
  padding-left: 24px;
}

#pdp-cplus .px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

#pdp-cplus .py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

#pdp-cplus .p-24 {
  padding: 24px;
}

#pdp-cplus .mt-32 {
  margin-top: 32px;
}

#pdp-cplus .mr-32 {
  margin-right: 32px;
}

#pdp-cplus .mb-32 {
  margin-bottom: 32px;
}

#pdp-cplus .ml-32 {
  margin-left: 32px;
}

#pdp-cplus .mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}

#pdp-cplus .-mx-32 {
  margin-left: -32px;
  margin-right: -32px;
}

#pdp-cplus .my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

#pdp-cplus .m-32 {
  margin: 32px;
}

#pdp-cplus .pt-32 {
  padding-top: 32px;
}

#pdp-cplus .pr-32 {
  padding-right: 32px;
}

#pdp-cplus .pb-32 {
  padding-bottom: 32px;
}

#pdp-cplus .pl-32 {
  padding-left: 32px;
}

#pdp-cplus .px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

#pdp-cplus .py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

#pdp-cplus .p-32 {
  padding: 32px;
}

#pdp-cplus .mt-40 {
  margin-top: 40px;
}

#pdp-cplus .mr-40 {
  margin-right: 40px;
}

#pdp-cplus .mb-40 {
  margin-bottom: 40px;
}

#pdp-cplus .ml-40 {
  margin-left: 40px;
}

#pdp-cplus .mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

#pdp-cplus .-mx-40 {
  margin-left: -40px;
  margin-right: -40px;
}

#pdp-cplus .my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

#pdp-cplus .m-40 {
  margin: 40px;
}

#pdp-cplus .pt-40 {
  padding-top: 40px;
}

#pdp-cplus .pr-40 {
  padding-right: 40px;
}

#pdp-cplus .pb-40 {
  padding-bottom: 40px;
}

#pdp-cplus .pl-40 {
  padding-left: 40px;
}

#pdp-cplus .px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

#pdp-cplus .py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

#pdp-cplus .p-40 {
  padding: 40px;
}

#pdp-cplus .mt-48 {
  margin-top: 48px;
}

#pdp-cplus .mr-48 {
  margin-right: 48px;
}

#pdp-cplus .mb-48 {
  margin-bottom: 48px;
}

#pdp-cplus .ml-48 {
  margin-left: 48px;
}

#pdp-cplus .mx-48 {
  margin-left: 48px;
  margin-right: 48px;
}

#pdp-cplus .-mx-48 {
  margin-left: -48px;
  margin-right: -48px;
}

#pdp-cplus .my-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

#pdp-cplus .m-48 {
  margin: 48px;
}

#pdp-cplus .pt-48 {
  padding-top: 48px;
}

#pdp-cplus .pr-48 {
  padding-right: 48px;
}

#pdp-cplus .pb-48 {
  padding-bottom: 48px;
}

#pdp-cplus .pl-48 {
  padding-left: 48px;
}

#pdp-cplus .px-48 {
  padding-left: 48px;
  padding-right: 48px;
}

#pdp-cplus .py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

#pdp-cplus .p-48 {
  padding: 48px;
}

#pdp-cplus .mt-64 {
  margin-top: 64px;
}

#pdp-cplus .mr-64 {
  margin-right: 64px;
}

#pdp-cplus .mb-64 {
  margin-bottom: 64px;
}

#pdp-cplus .ml-64 {
  margin-left: 64px;
}

#pdp-cplus .mx-64 {
  margin-left: 64px;
  margin-right: 64px;
}

#pdp-cplus .-mx-64 {
  margin-left: -64px;
  margin-right: -64px;
}

#pdp-cplus .my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

#pdp-cplus .m-64 {
  margin: 64px;
}

#pdp-cplus .pt-64 {
  padding-top: 64px;
}

#pdp-cplus .pr-64 {
  padding-right: 64px;
}

#pdp-cplus .pb-64 {
  padding-bottom: 64px;
}

#pdp-cplus .pl-64 {
  padding-left: 64px;
}

#pdp-cplus .px-64 {
  padding-left: 64px;
  padding-right: 64px;
}

#pdp-cplus .py-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

#pdp-cplus .p-64 {
  padding: 64px;
}

#pdp-cplus .mt-80 {
  margin-top: 80px;
}

#pdp-cplus .mr-80 {
  margin-right: 80px;
}

#pdp-cplus .mb-80 {
  margin-bottom: 80px;
}

#pdp-cplus .ml-80 {
  margin-left: 80px;
}

#pdp-cplus .mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

#pdp-cplus .-mx-80 {
  margin-left: -80px;
  margin-right: -80px;
}

#pdp-cplus .my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

#pdp-cplus .m-80 {
  margin: 80px;
}

#pdp-cplus .pt-80 {
  padding-top: 80px;
}

#pdp-cplus .pr-80 {
  padding-right: 80px;
}

#pdp-cplus .pb-80 {
  padding-bottom: 80px;
}

#pdp-cplus .pl-80 {
  padding-left: 80px;
}

#pdp-cplus .px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

#pdp-cplus .py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

#pdp-cplus .p-80 {
  padding: 80px;
}

#pdp-cplus .mt-96 {
  margin-top: 96px;
}

#pdp-cplus .mr-96 {
  margin-right: 96px;
}

#pdp-cplus .mb-96 {
  margin-bottom: 96px;
}

#pdp-cplus .ml-96 {
  margin-left: 96px;
}

#pdp-cplus .mx-96 {
  margin-left: 96px;
  margin-right: 96px;
}

#pdp-cplus .-mx-96 {
  margin-left: -96px;
  margin-right: -96px;
}

#pdp-cplus .my-96 {
  margin-top: 96px;
  margin-bottom: 96px;
}

#pdp-cplus .m-96 {
  margin: 96px;
}

#pdp-cplus .pt-96 {
  padding-top: 96px;
}

#pdp-cplus .pr-96 {
  padding-right: 96px;
}

#pdp-cplus .pb-96 {
  padding-bottom: 96px;
}

#pdp-cplus .pl-96 {
  padding-left: 96px;
}

#pdp-cplus .px-96 {
  padding-left: 96px;
  padding-right: 96px;
}

#pdp-cplus .py-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

#pdp-cplus .p-96 {
  padding: 96px;
}

#pdp-cplus .mt-112 {
  margin-top: 112px;
}

#pdp-cplus .mr-112 {
  margin-right: 112px;
}

#pdp-cplus .mb-112 {
  margin-bottom: 112px;
}

#pdp-cplus .ml-112 {
  margin-left: 112px;
}

#pdp-cplus .mx-112 {
  margin-left: 112px;
  margin-right: 112px;
}

#pdp-cplus .-mx-112 {
  margin-left: -112px;
  margin-right: -112px;
}

#pdp-cplus .my-112 {
  margin-top: 112px;
  margin-bottom: 112px;
}

#pdp-cplus .m-112 {
  margin: 112px;
}

#pdp-cplus .pt-112 {
  padding-top: 112px;
}

#pdp-cplus .pr-112 {
  padding-right: 112px;
}

#pdp-cplus .pb-112 {
  padding-bottom: 112px;
}

#pdp-cplus .pl-112 {
  padding-left: 112px;
}

#pdp-cplus .px-112 {
  padding-left: 112px;
  padding-right: 112px;
}

#pdp-cplus .py-112 {
  padding-top: 112px;
  padding-bottom: 112px;
}

#pdp-cplus .p-112 {
  padding: 112px;
}

#pdp-cplus .mt-128 {
  margin-top: 128px;
}

#pdp-cplus .mr-128 {
  margin-right: 128px;
}

#pdp-cplus .mb-128 {
  margin-bottom: 128px;
}

#pdp-cplus .ml-128 {
  margin-left: 128px;
}

#pdp-cplus .mx-128 {
  margin-left: 128px;
  margin-right: 128px;
}

#pdp-cplus .-mx-128 {
  margin-left: -128px;
  margin-right: -128px;
}

#pdp-cplus .my-128 {
  margin-top: 128px;
  margin-bottom: 128px;
}

#pdp-cplus .m-128 {
  margin: 128px;
}

#pdp-cplus .pt-128 {
  padding-top: 128px;
}

#pdp-cplus .pr-128 {
  padding-right: 128px;
}

#pdp-cplus .pb-128 {
  padding-bottom: 128px;
}

#pdp-cplus .pl-128 {
  padding-left: 128px;
}

#pdp-cplus .px-128 {
  padding-left: 128px;
  padding-right: 128px;
}

#pdp-cplus .py-128 {
  padding-top: 128px;
  padding-bottom: 128px;
}

#pdp-cplus .p-128 {
  padding: 128px;
}

#pdp-cplus .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#pdp-cplus .ml-auto {
  margin-left: auto;
}

#pdp-cplus .mr-auto {
  margin-right: auto;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .sm\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .sm\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .sm\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .sm\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .sm\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .sm\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .sm\:m-0 {
    margin: 0;
  }

  #pdp-cplus .sm\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .sm\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .sm\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .sm\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .sm\:p-0 {
    padding: 0;
  }

  #pdp-cplus .sm\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .sm\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .sm\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .sm\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .sm\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .sm\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .sm\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .sm\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .sm\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .sm\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .sm\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .sm\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .sm\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .sm\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .sm\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .sm\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .sm\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .sm\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .sm\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .sm\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .sm\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .sm\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .sm\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .sm\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .sm\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .sm\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .sm\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .sm\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .sm\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .sm\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .sm\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .sm\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .sm\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .sm\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .sm\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .sm\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .sm\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .sm\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .sm\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .sm\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .sm\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .sm\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .sm\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .sm\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .sm\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .sm\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .sm\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .sm\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .sm\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .sm\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .sm\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .sm\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .sm\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .sm\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .sm\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .sm\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .sm\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .sm\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .sm\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .sm\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .sm\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .sm\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .sm\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .sm\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .sm\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .sm\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .sm\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .sm\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .sm\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .sm\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .sm\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .sm\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .sm\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .sm\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .sm\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .sm\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .sm\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .sm\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .sm\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .sm\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .sm\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .sm\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .sm\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .sm\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .sm\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .sm\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .sm\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .sm\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .sm\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .sm\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .sm\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .sm\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .sm\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .sm\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .sm\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .sm\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .sm\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .sm\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .sm\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .sm\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .sm\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .sm\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .sm\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .sm\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .sm\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .sm\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .sm\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .sm\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .sm\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .sm\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .sm\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .sm\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .sm\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .sm\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .sm\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .sm\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .sm\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .sm\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .sm\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .sm\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .sm\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .sm\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .sm\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .sm\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .sm\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .sm\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .sm\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .sm\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .sm\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .sm\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .sm\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .sm\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .sm\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .sm\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .sm\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .sm\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .sm\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .sm\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .sm\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .sm\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .sm\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .sm\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .sm\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .sm\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .sm\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .sm\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .sm\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .sm\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .sm\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .sm\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .sm\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .sm\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .sm\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .sm\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .sm\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .sm\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .sm\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .sm\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .sm\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .sm\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .sm\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .sm\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .sm\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .sm\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .sm\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .sm\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .sm\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .sm\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .sm\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .sm\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .sm\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .sm\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .sm\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .sm\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .sm\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .sm\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .sm\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .sm\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .sm\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .sm\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .sm\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .sm\:p-128 {
    padding: 128px;
  }
}

@media only screen and (min-width: 768px) {
  #pdp-cplus .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .md\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .md\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .md\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .md\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .md\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .md\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .md\:m-0 {
    margin: 0;
  }

  #pdp-cplus .md\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .md\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .md\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .md\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .md\:p-0 {
    padding: 0;
  }

  #pdp-cplus .md\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .md\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .md\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .md\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .md\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .md\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .md\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .md\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .md\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .md\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .md\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .md\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .md\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .md\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .md\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .md\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .md\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .md\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .md\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .md\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .md\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .md\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .md\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .md\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .md\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .md\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .md\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .md\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .md\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .md\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .md\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .md\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .md\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .md\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .md\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .md\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .md\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .md\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .md\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .md\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .md\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .md\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .md\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .md\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .md\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .md\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .md\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .md\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .md\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .md\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .md\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .md\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .md\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .md\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .md\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .md\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .md\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .md\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .md\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .md\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .md\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .md\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .md\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .md\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .md\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .md\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .md\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .md\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .md\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .md\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .md\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .md\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .md\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .md\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .md\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .md\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .md\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .md\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .md\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .md\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .md\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .md\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .md\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .md\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .md\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .md\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .md\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .md\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .md\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .md\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .md\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .md\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .md\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .md\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .md\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .md\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .md\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .md\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .md\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .md\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .md\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .md\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .md\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .md\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .md\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .md\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .md\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .md\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .md\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .md\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .md\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .md\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .md\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .md\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .md\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .md\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .md\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .md\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .md\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .md\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .md\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .md\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .md\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .md\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .md\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .md\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .md\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .md\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .md\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .md\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .md\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .md\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .md\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .md\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .md\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .md\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .md\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .md\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .md\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .md\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .md\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .md\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .md\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .md\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .md\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .md\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .md\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .md\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .md\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .md\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .md\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .md\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .md\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .md\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .md\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .md\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .md\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .md\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .md\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .md\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .md\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .md\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .md\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .md\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .md\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .md\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .md\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .md\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .md\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .md\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .md\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .md\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .md\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .md\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .md\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .md\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .md\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .md\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .md\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .md\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .md\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .md\:p-128 {
    padding: 128px;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .lg\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .lg\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .lg\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .lg\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .lg\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .lg\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .lg\:m-0 {
    margin: 0;
  }

  #pdp-cplus .lg\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .lg\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .lg\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .lg\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .lg\:p-0 {
    padding: 0;
  }

  #pdp-cplus .lg\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .lg\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .lg\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .lg\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .lg\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .lg\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .lg\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .lg\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .lg\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .lg\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .lg\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .lg\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .lg\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .lg\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .lg\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .lg\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .lg\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .lg\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .lg\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .lg\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .lg\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .lg\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .lg\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .lg\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .lg\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .lg\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .lg\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .lg\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .lg\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .lg\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .lg\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .lg\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .lg\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .lg\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .lg\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .lg\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .lg\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .lg\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .lg\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .lg\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .lg\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .lg\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .lg\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .lg\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .lg\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .lg\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .lg\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .lg\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .lg\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .lg\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .lg\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .lg\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .lg\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .lg\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .lg\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .lg\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .lg\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .lg\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .lg\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .lg\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .lg\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .lg\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .lg\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .lg\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .lg\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .lg\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .lg\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .lg\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .lg\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .lg\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .lg\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .lg\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .lg\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .lg\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .lg\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .lg\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .lg\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .lg\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .lg\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .lg\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .lg\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .lg\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .lg\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .lg\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .lg\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .lg\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .lg\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .lg\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .lg\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .lg\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .lg\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .lg\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .lg\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .lg\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .lg\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .lg\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .lg\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .lg\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .lg\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .lg\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .lg\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .lg\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .lg\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .lg\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .lg\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .lg\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .lg\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .lg\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .lg\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .lg\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .lg\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .lg\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .lg\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .lg\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .lg\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .lg\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .lg\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .lg\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .lg\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .lg\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .lg\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .lg\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .lg\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .lg\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .lg\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .lg\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .lg\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .lg\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .lg\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .lg\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .lg\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .lg\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .lg\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .lg\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .lg\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .lg\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .lg\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .lg\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .lg\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .lg\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .lg\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .lg\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .lg\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .lg\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .lg\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .lg\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .lg\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .lg\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .lg\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .lg\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .lg\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .lg\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .lg\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .lg\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .lg\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .lg\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .lg\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .lg\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .lg\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .lg\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .lg\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .lg\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .lg\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .lg\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .lg\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .lg\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .lg\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .lg\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .lg\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .lg\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .lg\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .lg\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .lg\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .lg\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .lg\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .lg\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .lg\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .lg\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .lg\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .lg\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .lg\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .lg\:p-128 {
    padding: 128px;
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus .xlg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .xlg\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .xlg\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .xlg\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .xlg\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .xlg\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .xlg\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .xlg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .xlg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .xlg\:m-0 {
    margin: 0;
  }

  #pdp-cplus .xlg\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .xlg\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .xlg\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .xlg\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .xlg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .xlg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .xlg\:p-0 {
    padding: 0;
  }

  #pdp-cplus .xlg\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .xlg\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .xlg\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .xlg\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .xlg\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .xlg\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .xlg\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .xlg\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .xlg\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .xlg\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .xlg\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .xlg\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .xlg\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .xlg\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .xlg\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .xlg\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .xlg\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .xlg\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .xlg\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .xlg\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .xlg\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .xlg\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .xlg\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .xlg\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .xlg\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .xlg\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .xlg\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .xlg\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .xlg\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .xlg\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .xlg\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .xlg\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .xlg\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .xlg\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .xlg\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .xlg\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .xlg\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .xlg\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .xlg\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .xlg\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .xlg\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .xlg\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .xlg\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .xlg\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .xlg\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .xlg\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .xlg\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .xlg\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .xlg\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .xlg\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .xlg\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .xlg\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .xlg\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .xlg\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .xlg\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .xlg\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .xlg\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .xlg\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .xlg\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .xlg\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .xlg\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .xlg\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .xlg\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .xlg\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .xlg\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .xlg\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .xlg\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .xlg\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .xlg\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .xlg\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .xlg\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .xlg\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .xlg\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .xlg\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .xlg\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .xlg\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .xlg\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .xlg\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .xlg\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .xlg\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .xlg\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .xlg\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .xlg\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .xlg\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .xlg\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .xlg\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .xlg\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .xlg\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .xlg\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .xlg\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .xlg\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .xlg\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .xlg\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .xlg\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .xlg\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .xlg\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .xlg\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .xlg\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .xlg\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .xlg\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .xlg\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .xlg\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .xlg\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .xlg\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .xlg\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .xlg\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .xlg\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .xlg\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .xlg\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .xlg\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .xlg\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .xlg\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .xlg\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .xlg\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .xlg\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .xlg\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .xlg\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .xlg\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .xlg\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .xlg\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .xlg\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .xlg\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .xlg\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .xlg\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .xlg\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .xlg\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .xlg\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .xlg\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .xlg\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .xlg\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .xlg\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .xlg\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .xlg\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .xlg\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .xlg\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .xlg\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .xlg\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .xlg\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .xlg\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .xlg\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .xlg\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .xlg\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .xlg\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .xlg\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .xlg\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .xlg\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .xlg\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .xlg\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .xlg\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .xlg\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .xlg\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .xlg\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .xlg\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .xlg\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .xlg\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .xlg\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .xlg\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .xlg\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .xlg\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .xlg\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .xlg\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .xlg\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .xlg\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .xlg\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .xlg\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .xlg\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .xlg\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .xlg\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .xlg\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .xlg\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .xlg\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .xlg\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .xlg\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .xlg\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .xlg\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .xlg\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .xlg\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .xlg\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .xlg\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .xlg\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .xlg\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .xlg\:p-128 {
    padding: 128px;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .xl\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .xl\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .xl\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .xl\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .xl\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .xl\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .xl\:m-0 {
    margin: 0;
  }

  #pdp-cplus .xl\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .xl\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .xl\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .xl\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .xl\:p-0 {
    padding: 0;
  }

  #pdp-cplus .xl\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .xl\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .xl\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .xl\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .xl\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .xl\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .xl\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .xl\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .xl\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .xl\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .xl\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .xl\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .xl\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .xl\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .xl\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .xl\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .xl\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .xl\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .xl\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .xl\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .xl\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .xl\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .xl\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .xl\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .xl\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .xl\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .xl\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .xl\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .xl\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .xl\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .xl\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .xl\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .xl\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .xl\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .xl\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .xl\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .xl\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .xl\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .xl\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .xl\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .xl\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .xl\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .xl\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .xl\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .xl\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .xl\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .xl\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .xl\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .xl\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .xl\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .xl\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .xl\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .xl\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .xl\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .xl\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .xl\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .xl\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .xl\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .xl\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .xl\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .xl\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .xl\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .xl\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .xl\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .xl\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .xl\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .xl\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .xl\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .xl\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .xl\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .xl\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .xl\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .xl\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .xl\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .xl\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .xl\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .xl\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .xl\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .xl\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .xl\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .xl\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .xl\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .xl\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .xl\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .xl\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .xl\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .xl\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .xl\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .xl\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .xl\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .xl\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .xl\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .xl\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .xl\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .xl\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .xl\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .xl\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .xl\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .xl\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .xl\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .xl\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .xl\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .xl\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .xl\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .xl\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .xl\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .xl\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .xl\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .xl\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .xl\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .xl\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .xl\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .xl\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .xl\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .xl\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .xl\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .xl\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .xl\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .xl\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .xl\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .xl\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .xl\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .xl\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .xl\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .xl\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .xl\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .xl\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .xl\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .xl\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .xl\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .xl\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .xl\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .xl\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .xl\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .xl\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .xl\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .xl\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .xl\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .xl\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .xl\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .xl\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .xl\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .xl\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .xl\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .xl\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .xl\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .xl\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .xl\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .xl\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .xl\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .xl\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .xl\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .xl\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .xl\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .xl\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .xl\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .xl\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .xl\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .xl\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .xl\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .xl\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .xl\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .xl\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .xl\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .xl\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .xl\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .xl\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .xl\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .xl\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .xl\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .xl\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .xl\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .xl\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .xl\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .xl\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .xl\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .xl\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .xl\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .xl\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .xl\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .xl\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .xl\:p-128 {
    padding: 128px;
  }
}

@media only screen and (min-width: 1920px) {
  #pdp-cplus .xl2\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  #pdp-cplus .xl2\:ml-auto {
    margin-left: auto;
  }

  #pdp-cplus .xl2\:mr-auto {
    margin-right: auto;
  }

  #pdp-cplus .xl2\:mt-0 {
    margin-top: 0;
  }

  #pdp-cplus .xl2\:mr-0 {
    margin-right: 0;
  }

  #pdp-cplus .xl2\:mb-0 {
    margin-bottom: 0;
  }

  #pdp-cplus .xl2\:ml-0 {
    margin-left: 0;
  }

  #pdp-cplus .xl2\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #pdp-cplus .xl2\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  #pdp-cplus .xl2\:m-0 {
    margin: 0;
  }

  #pdp-cplus .xl2\:pt-0 {
    padding-top: 0;
  }

  #pdp-cplus .xl2\:pr-0 {
    padding-right: 0;
  }

  #pdp-cplus .xl2\:pb-0 {
    padding-bottom: 0;
  }

  #pdp-cplus .xl2\:pl-0 {
    padding-left: 0;
  }

  #pdp-cplus .xl2\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  #pdp-cplus .xl2\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #pdp-cplus .xl2\:p-0 {
    padding: 0;
  }

  #pdp-cplus .xl2\:mt-4 {
    margin-top: 4px;
  }

  #pdp-cplus .xl2\:mr-4 {
    margin-right: 4px;
  }

  #pdp-cplus .xl2\:mb-4 {
    margin-bottom: 4px;
  }

  #pdp-cplus .xl2\:ml-4 {
    margin-left: 4px;
  }

  #pdp-cplus .xl2\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  #pdp-cplus .xl2\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  #pdp-cplus .xl2\:m-4 {
    margin: 4px;
  }

  #pdp-cplus .xl2\:pt-4 {
    padding-top: 4px;
  }

  #pdp-cplus .xl2\:pr-4 {
    padding-right: 4px;
  }

  #pdp-cplus .xl2\:pb-4 {
    padding-bottom: 4px;
  }

  #pdp-cplus .xl2\:pl-4 {
    padding-left: 4px;
  }

  #pdp-cplus .xl2\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  #pdp-cplus .xl2\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  #pdp-cplus .xl2\:p-4 {
    padding: 4px;
  }

  #pdp-cplus .xl2\:mt-8 {
    margin-top: 8px;
  }

  #pdp-cplus .xl2\:mr-8 {
    margin-right: 8px;
  }

  #pdp-cplus .xl2\:mb-8 {
    margin-bottom: 8px;
  }

  #pdp-cplus .xl2\:ml-8 {
    margin-left: 8px;
  }

  #pdp-cplus .xl2\:mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #pdp-cplus .xl2\:my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #pdp-cplus .xl2\:m-8 {
    margin: 8px;
  }

  #pdp-cplus .xl2\:pt-8 {
    padding-top: 8px;
  }

  #pdp-cplus .xl2\:pr-8 {
    padding-right: 8px;
  }

  #pdp-cplus .xl2\:pb-8 {
    padding-bottom: 8px;
  }

  #pdp-cplus .xl2\:pl-8 {
    padding-left: 8px;
  }

  #pdp-cplus .xl2\:px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #pdp-cplus .xl2\:py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #pdp-cplus .xl2\:p-8 {
    padding: 8px;
  }

  #pdp-cplus .xl2\:mt-12 {
    margin-top: 12px;
  }

  #pdp-cplus .xl2\:mr-12 {
    margin-right: 12px;
  }

  #pdp-cplus .xl2\:mb-12 {
    margin-bottom: 12px;
  }

  #pdp-cplus .xl2\:ml-12 {
    margin-left: 12px;
  }

  #pdp-cplus .xl2\:mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #pdp-cplus .xl2\:my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  #pdp-cplus .xl2\:m-12 {
    margin: 12px;
  }

  #pdp-cplus .xl2\:pt-12 {
    padding-top: 12px;
  }

  #pdp-cplus .xl2\:pr-12 {
    padding-right: 12px;
  }

  #pdp-cplus .xl2\:pb-12 {
    padding-bottom: 12px;
  }

  #pdp-cplus .xl2\:pl-12 {
    padding-left: 12px;
  }

  #pdp-cplus .xl2\:px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  #pdp-cplus .xl2\:py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #pdp-cplus .xl2\:p-12 {
    padding: 12px;
  }

  #pdp-cplus .xl2\:mt-16 {
    margin-top: 16px;
  }

  #pdp-cplus .xl2\:mr-16 {
    margin-right: 16px;
  }

  #pdp-cplus .xl2\:mb-16 {
    margin-bottom: 16px;
  }

  #pdp-cplus .xl2\:ml-16 {
    margin-left: 16px;
  }

  #pdp-cplus .xl2\:mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }

  #pdp-cplus .xl2\:my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #pdp-cplus .xl2\:m-16 {
    margin: 16px;
  }

  #pdp-cplus .xl2\:pt-16 {
    padding-top: 16px;
  }

  #pdp-cplus .xl2\:pr-16 {
    padding-right: 16px;
  }

  #pdp-cplus .xl2\:pb-16 {
    padding-bottom: 16px;
  }

  #pdp-cplus .xl2\:pl-16 {
    padding-left: 16px;
  }

  #pdp-cplus .xl2\:px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #pdp-cplus .xl2\:py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  #pdp-cplus .xl2\:p-16 {
    padding: 16px;
  }

  #pdp-cplus .xl2\:mt-24 {
    margin-top: 24px;
  }

  #pdp-cplus .xl2\:mr-24 {
    margin-right: 24px;
  }

  #pdp-cplus .xl2\:mb-24 {
    margin-bottom: 24px;
  }

  #pdp-cplus .xl2\:ml-24 {
    margin-left: 24px;
  }

  #pdp-cplus .xl2\:mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #pdp-cplus .xl2\:my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  #pdp-cplus .xl2\:m-24 {
    margin: 24px;
  }

  #pdp-cplus .xl2\:pt-24 {
    padding-top: 24px;
  }

  #pdp-cplus .xl2\:pr-24 {
    padding-right: 24px;
  }

  #pdp-cplus .xl2\:pb-24 {
    padding-bottom: 24px;
  }

  #pdp-cplus .xl2\:pl-24 {
    padding-left: 24px;
  }

  #pdp-cplus .xl2\:px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #pdp-cplus .xl2\:py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #pdp-cplus .xl2\:p-24 {
    padding: 24px;
  }

  #pdp-cplus .xl2\:mt-32 {
    margin-top: 32px;
  }

  #pdp-cplus .xl2\:mr-32 {
    margin-right: 32px;
  }

  #pdp-cplus .xl2\:mb-32 {
    margin-bottom: 32px;
  }

  #pdp-cplus .xl2\:ml-32 {
    margin-left: 32px;
  }

  #pdp-cplus .xl2\:mx-32 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #pdp-cplus .xl2\:my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  #pdp-cplus .xl2\:m-32 {
    margin: 32px;
  }

  #pdp-cplus .xl2\:pt-32 {
    padding-top: 32px;
  }

  #pdp-cplus .xl2\:pr-32 {
    padding-right: 32px;
  }

  #pdp-cplus .xl2\:pb-32 {
    padding-bottom: 32px;
  }

  #pdp-cplus .xl2\:pl-32 {
    padding-left: 32px;
  }

  #pdp-cplus .xl2\:px-32 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #pdp-cplus .xl2\:py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #pdp-cplus .xl2\:p-32 {
    padding: 32px;
  }

  #pdp-cplus .xl2\:mt-40 {
    margin-top: 40px;
  }

  #pdp-cplus .xl2\:mr-40 {
    margin-right: 40px;
  }

  #pdp-cplus .xl2\:mb-40 {
    margin-bottom: 40px;
  }

  #pdp-cplus .xl2\:ml-40 {
    margin-left: 40px;
  }

  #pdp-cplus .xl2\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #pdp-cplus .xl2\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #pdp-cplus .xl2\:m-40 {
    margin: 40px;
  }

  #pdp-cplus .xl2\:pt-40 {
    padding-top: 40px;
  }

  #pdp-cplus .xl2\:pr-40 {
    padding-right: 40px;
  }

  #pdp-cplus .xl2\:pb-40 {
    padding-bottom: 40px;
  }

  #pdp-cplus .xl2\:pl-40 {
    padding-left: 40px;
  }

  #pdp-cplus .xl2\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #pdp-cplus .xl2\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #pdp-cplus .xl2\:p-40 {
    padding: 40px;
  }

  #pdp-cplus .xl2\:mt-48 {
    margin-top: 48px;
  }

  #pdp-cplus .xl2\:mr-48 {
    margin-right: 48px;
  }

  #pdp-cplus .xl2\:mb-48 {
    margin-bottom: 48px;
  }

  #pdp-cplus .xl2\:ml-48 {
    margin-left: 48px;
  }

  #pdp-cplus .xl2\:mx-48 {
    margin-left: 48px;
    margin-right: 48px;
  }

  #pdp-cplus .xl2\:my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  #pdp-cplus .xl2\:m-48 {
    margin: 48px;
  }

  #pdp-cplus .xl2\:pt-48 {
    padding-top: 48px;
  }

  #pdp-cplus .xl2\:pr-48 {
    padding-right: 48px;
  }

  #pdp-cplus .xl2\:pb-48 {
    padding-bottom: 48px;
  }

  #pdp-cplus .xl2\:pl-48 {
    padding-left: 48px;
  }

  #pdp-cplus .xl2\:px-48 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #pdp-cplus .xl2\:py-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  #pdp-cplus .xl2\:p-48 {
    padding: 48px;
  }

  #pdp-cplus .xl2\:mt-64 {
    margin-top: 64px;
  }

  #pdp-cplus .xl2\:mr-64 {
    margin-right: 64px;
  }

  #pdp-cplus .xl2\:mb-64 {
    margin-bottom: 64px;
  }

  #pdp-cplus .xl2\:ml-64 {
    margin-left: 64px;
  }

  #pdp-cplus .xl2\:mx-64 {
    margin-left: 64px;
    margin-right: 64px;
  }

  #pdp-cplus .xl2\:my-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  #pdp-cplus .xl2\:m-64 {
    margin: 64px;
  }

  #pdp-cplus .xl2\:pt-64 {
    padding-top: 64px;
  }

  #pdp-cplus .xl2\:pr-64 {
    padding-right: 64px;
  }

  #pdp-cplus .xl2\:pb-64 {
    padding-bottom: 64px;
  }

  #pdp-cplus .xl2\:pl-64 {
    padding-left: 64px;
  }

  #pdp-cplus .xl2\:px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }

  #pdp-cplus .xl2\:py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #pdp-cplus .xl2\:p-64 {
    padding: 64px;
  }

  #pdp-cplus .xl2\:mt-80 {
    margin-top: 80px;
  }

  #pdp-cplus .xl2\:mr-80 {
    margin-right: 80px;
  }

  #pdp-cplus .xl2\:mb-80 {
    margin-bottom: 80px;
  }

  #pdp-cplus .xl2\:ml-80 {
    margin-left: 80px;
  }

  #pdp-cplus .xl2\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  #pdp-cplus .xl2\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  #pdp-cplus .xl2\:m-80 {
    margin: 80px;
  }

  #pdp-cplus .xl2\:pt-80 {
    padding-top: 80px;
  }

  #pdp-cplus .xl2\:pr-80 {
    padding-right: 80px;
  }

  #pdp-cplus .xl2\:pb-80 {
    padding-bottom: 80px;
  }

  #pdp-cplus .xl2\:pl-80 {
    padding-left: 80px;
  }

  #pdp-cplus .xl2\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #pdp-cplus .xl2\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #pdp-cplus .xl2\:p-80 {
    padding: 80px;
  }

  #pdp-cplus .xl2\:mt-96 {
    margin-top: 96px;
  }

  #pdp-cplus .xl2\:mr-96 {
    margin-right: 96px;
  }

  #pdp-cplus .xl2\:mb-96 {
    margin-bottom: 96px;
  }

  #pdp-cplus .xl2\:ml-96 {
    margin-left: 96px;
  }

  #pdp-cplus .xl2\:mx-96 {
    margin-left: 96px;
    margin-right: 96px;
  }

  #pdp-cplus .xl2\:my-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  #pdp-cplus .xl2\:m-96 {
    margin: 96px;
  }

  #pdp-cplus .xl2\:pt-96 {
    padding-top: 96px;
  }

  #pdp-cplus .xl2\:pr-96 {
    padding-right: 96px;
  }

  #pdp-cplus .xl2\:pb-96 {
    padding-bottom: 96px;
  }

  #pdp-cplus .xl2\:pl-96 {
    padding-left: 96px;
  }

  #pdp-cplus .xl2\:px-96 {
    padding-left: 96px;
    padding-right: 96px;
  }

  #pdp-cplus .xl2\:py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  #pdp-cplus .xl2\:p-96 {
    padding: 96px;
  }

  #pdp-cplus .xl2\:mt-112 {
    margin-top: 112px;
  }

  #pdp-cplus .xl2\:mr-112 {
    margin-right: 112px;
  }

  #pdp-cplus .xl2\:mb-112 {
    margin-bottom: 112px;
  }

  #pdp-cplus .xl2\:ml-112 {
    margin-left: 112px;
  }

  #pdp-cplus .xl2\:mx-112 {
    margin-left: 112px;
    margin-right: 112px;
  }

  #pdp-cplus .xl2\:my-112 {
    margin-top: 112px;
    margin-bottom: 112px;
  }

  #pdp-cplus .xl2\:m-112 {
    margin: 112px;
  }

  #pdp-cplus .xl2\:pt-112 {
    padding-top: 112px;
  }

  #pdp-cplus .xl2\:pr-112 {
    padding-right: 112px;
  }

  #pdp-cplus .xl2\:pb-112 {
    padding-bottom: 112px;
  }

  #pdp-cplus .xl2\:pl-112 {
    padding-left: 112px;
  }

  #pdp-cplus .xl2\:px-112 {
    padding-left: 112px;
    padding-right: 112px;
  }

  #pdp-cplus .xl2\:py-112 {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  #pdp-cplus .xl2\:p-112 {
    padding: 112px;
  }

  #pdp-cplus .xl2\:mt-128 {
    margin-top: 128px;
  }

  #pdp-cplus .xl2\:mr-128 {
    margin-right: 128px;
  }

  #pdp-cplus .xl2\:mb-128 {
    margin-bottom: 128px;
  }

  #pdp-cplus .xl2\:ml-128 {
    margin-left: 128px;
  }

  #pdp-cplus .xl2\:mx-128 {
    margin-left: 128px;
    margin-right: 128px;
  }

  #pdp-cplus .xl2\:my-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }

  #pdp-cplus .xl2\:m-128 {
    margin: 128px;
  }

  #pdp-cplus .xl2\:pt-128 {
    padding-top: 128px;
  }

  #pdp-cplus .xl2\:pr-128 {
    padding-right: 128px;
  }

  #pdp-cplus .xl2\:pb-128 {
    padding-bottom: 128px;
  }

  #pdp-cplus .xl2\:pl-128 {
    padding-left: 128px;
  }

  #pdp-cplus .xl2\:px-128 {
    padding-left: 128px;
    padding-right: 128px;
  }

  #pdp-cplus .xl2\:py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  #pdp-cplus .xl2\:p-128 {
    padding: 128px;
  }
}

#pdp-cplus .link,
#pdp-cplus .link--arrow,
#pdp-cplus .link--arrow-down,
#pdp-cplus .link--arrow-right,
#pdp-cplus .link--arrow-up {
  background-color: transparent;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}

#pdp-cplus .link--arrow-down:focus,
#pdp-cplus .link--arrow-down:hover,
#pdp-cplus .link--arrow-right:focus,
#pdp-cplus .link--arrow-right:hover,
#pdp-cplus .link--arrow-up:focus,
#pdp-cplus .link--arrow-up:hover,
#pdp-cplus .link--arrow:focus,
#pdp-cplus .link--arrow:hover,
#pdp-cplus .link:focus,
#pdp-cplus .link:hover {
  color: #09f;
}

#pdp-cplus .link--arrow-down:active,
#pdp-cplus .link--arrow-right:active,
#pdp-cplus .link--arrow-up:active,
#pdp-cplus .link--arrow:active,
#pdp-cplus .link:active {
  color: #0d646f;
}

#pdp-cplus .link--arrow-down:active,
#pdp-cplus .link--arrow-down:focus,
#pdp-cplus .link--arrow-down:hover,
#pdp-cplus .link--arrow-right:active,
#pdp-cplus .link--arrow-right:focus,
#pdp-cplus .link--arrow-right:hover,
#pdp-cplus .link--arrow-up:active,
#pdp-cplus .link--arrow-up:focus,
#pdp-cplus .link--arrow-up:hover,
#pdp-cplus .link--arrow:active,
#pdp-cplus .link--arrow:focus,
#pdp-cplus .link--arrow:hover,
#pdp-cplus .link:active,
#pdp-cplus .link:focus,
#pdp-cplus .link:hover {
  outline: none;
}

#pdp-cplus .link--color {
  color: #0d646f;
}

#pdp-cplus .link--color:hover,
#pdp-cplus .link--light:hover {
  color: #09f;
}

#pdp-cplus .link--arrow-down:after,
#pdp-cplus .link--arrow-right:after,
#pdp-cplus .link--arrow-up:after,
#pdp-cplus .link--arrow:after {
  content: "";
  border-style: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: 3px 7px;
  padding: 3px;
  position: relative;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

#pdp-cplus .link--arrow-right:after {
  margin: 0 4px;
  transform: rotate(-45deg);
}

#pdp-cplus .link--arrow-up:after {
  margin: 0 7px;
  transform: rotate(-135deg) translateY(-2px);
}

#pdp-cplus .link--cheveron-animation svg {
  transition: transform 0.2s ease-in-out;
}

#pdp-cplus .link--cheveron-animation:hover svg {
  transform: translateX(4px);
}

#pdp-cplus .link--cheveron-animation-left svg {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}

#pdp-cplus .link--cheveron-animation-left:hover svg {
  transform: rotate(180deg) translateX(4px);
}

#pdp-cplus .btn-cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 32px;
  background-color: #0d646f;
  border-radius: 30px;
  outline: none;
  color: #f9f9f9;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease, border-color 0.2s ease;
}

@media (hover: hover) {
  #pdp-cplus .btn-cta:hover {
    background-color: #09f;
  }
}

#pdp-cplus .btn-cta:active {
  background-color: #09f;
}

#pdp-cplus .btn-cta:disabled {
  color: #d7dadb;
  background-color: #edf0f0;
  cursor: default;
}

#pdp-cplus .btn-cta:disabled path {
  fill: #d7dadb;
}

#pdp-cplus .btn-cta__icon {
  margin-right: 8px;
}

#pdp-cplus .btn-cta--hero {
  padding: 11px 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: -0.3px;
  min-width: max-content;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .btn-cta--hero {
    font-size: 20px;
  }
}

#pdp-cplus .btn-cta--border {
  padding: 9px 30px;
  background-color: transparent;
  color: #484444;
  border: 1px solid #484444;
}

@media (hover: hover) {
  #pdp-cplus .btn-cta--border:hover {
    background-color: #2f2f2f;
    border-color: #2f2f2f;
    color: #f9f9f9;
  }
}

#pdp-cplus .btn-cta--border:active {
  background-color: #484444;
  border-color: #484444;
  color: #f9f9f9;
}

#pdp-cplus .btn-cta--border:disabled {
  border-color: #cad0d0;
  color: #cad0d0;
  cursor: default;
}

#pdp-cplus .btn-cta--border:disabled path {
  fill: #cad0d0;
}

#pdp-cplus .btn-cta--border-white {
  padding: 9px 30px;
  background-color: transparent;
  color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

@media (hover: hover) {
  #pdp-cplus .btn-cta--border-white:hover {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    color: #484444;
  }
}

#pdp-cplus .btn-cta--border-white:active {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #484444;
}

#pdp-cplus .btn-cta--border-white:disabled {
  border-color: #484444;
  color: #484444;
  cursor: default;
}

#pdp-cplus .btn-cta--border-white:disabled path {
  fill: #484444;
}

#pdp-cplus .btn-link {
  display: inline-block;
  background-color: transparent;
  color: #0d646f;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: 0;
}

#pdp-cplus .btn-link--arrow-down:after,
#pdp-cplus .btn-link--arrow-right:after,
#pdp-cplus .btn-link--arrow-up:after {
  content: "";
  position: relative;
  top: -0.15em;
  display: inline-block;
  width: 0.3em;
  height: 0.3em;
  border-right: 0.1em solid;
  border-top: 0.1em solid;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  margin-left: 6px;
  transition: margin-left 0.2s ease-in-out;
}

#pdp-cplus .btn-link--arrow-up:after {
  transform: rotate(-45deg);
}

#pdp-cplus .btn-link--arrow-down:after {
  transform: rotate(135deg);
}

#pdp-cplus .btn-link:disabled {
  color: #cad0d0;
}

#pdp-cplus .btn-link--small {
  color: #484444;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0;
  text-transform: unset;
}

#pdp-cplus .btn-link.animated-arrow:hover:after {
  margin-left: 9px;
}

#pdp-cplus .btn-link.capitalize-first-letter:after {
  top: -1px;
}

#pdp-cplus .flickity-button {
  position: relative;
  align-items: center;
  background-color: transparent !important;
  box-sizing: border-box;
  display: flex;
  height: 60px !important;
  justify-content: center;
  transition: opacity 0.2s ease;
  width: 60px !important;
}

#pdp-cplus .flickity-button-icon {
  display: none;
}

#pdp-cplus .flickity-button:before {
  content: "";
  background-color: #fff;
  border: 1px solid #d7dadb;
  border-radius: 100%;
  box-sizing: border-box;
  height: 40px;
  transition: background-color 0.2s ease;
  width: 40px;
}

#pdp-cplus .flickity-button:focus,
#pdp-cplus .flickity-button:hover {
  outline: none !important;
  box-shadow: none !important;
}

#pdp-cplus .flickity-button:focus:before,
#pdp-cplus .flickity-button:hover:before {
  background-color: #f9f9f9;
}

#pdp-cplus .flickity-button:active {
  opacity: 0.75 !important;
}

#pdp-cplus .flickity-button:active:before {
  background-color: #f9f9f9;
}

#pdp-cplus .flickity-button:after {
  content: "";
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
  border-color: #484444;
  border-style: solid;
  border-width: 0 1px 1px 0;
  left: 23px;
  margin: 0;
  padding: 5px;
  position: absolute;
  top: 25px;
  transform: rotate(-45deg);
}

#pdp-cplus .flickity-button.previous:after {
  left: 27px;
  transform: rotate(135deg);
}

#pdp-cplus .flickity-button.disabled,
#pdp-cplus .flickity-button:disabled {
  pointer-events: none;
  opacity: 0 !important;
}

#pdp-cplus .border-1 {
  border-width: 1px;
}

#pdp-cplus .border-t-1 {
  border-top-width: 1px;
}

#pdp-cplus .border-radius-xl {
  border-radius: 12px;
}

#pdp-cplus .border-solid {
  border-style: solid;
}

#pdp-cplus .w-full {
  width: 100%;
}

#pdp-cplus .h-full {
  height: 100%;
}

#pdp-cplus .h-auto {
  height: auto;
}

#pdp-cplus .w-auto {
  width: auto;
}

#pdp-cplus .w-1\/2 {
  width: 50%;
}

#pdp-cplus .border-box {
  box-sizing: border-box;
}

#pdp-cplus .block {
  display: block;
}

#pdp-cplus .invisible {
  visibility: hidden;
}

#pdp-cplus .hidden {
  display: none;
}

#pdp-cplus .inline-block {
  display: inline-block;
}

#pdp-cplus .inline {
  display: inline;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .sm\:hidden {
    display: none;
  }

  #pdp-cplus .sm\:w-full {
    width: 100%;
  }

  #pdp-cplus .sm\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .sm\:w-auto {
    width: auto;
  }

  #pdp-cplus .sm\:h-full {
    height: 100%;
  }

  #pdp-cplus .sm\:h-auto {
    height: auto;
  }

  #pdp-cplus .sm\:block {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  #pdp-cplus .md\:hidden {
    display: none;
  }

  #pdp-cplus .md\:w-full {
    width: 100%;
  }

  #pdp-cplus .md\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .md\:w-auto {
    width: auto;
  }

  #pdp-cplus .md\:h-full {
    height: 100%;
  }

  #pdp-cplus .md\:h-auto {
    height: auto;
  }

  #pdp-cplus .md\:block {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .lg\:hidden {
    display: none;
  }

  #pdp-cplus .lg\:w-full {
    width: 100%;
  }

  #pdp-cplus .lg\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .lg\:w-auto {
    width: auto;
  }

  #pdp-cplus .lg\:h-full {
    height: 100%;
  }

  #pdp-cplus .lg\:h-auto {
    height: auto;
  }

  #pdp-cplus .lg\:block {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus .xlg\:hidden {
    display: none;
  }

  #pdp-cplus .xlg\:w-full {
    width: 100%;
  }

  #pdp-cplus .xlg\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .xlg\:w-auto {
    width: auto;
  }

  #pdp-cplus .xlg\:h-full {
    height: 100%;
  }

  #pdp-cplus .xlg\:h-auto {
    height: auto;
  }

  #pdp-cplus .xlg\:block {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl\:hidden {
    display: none;
  }

  #pdp-cplus .xl\:w-full {
    width: 100%;
  }

  #pdp-cplus .xl\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .xl\:w-auto {
    width: auto;
  }

  #pdp-cplus .xl\:h-full {
    height: 100%;
  }

  #pdp-cplus .xl\:h-auto {
    height: auto;
  }

  #pdp-cplus .xl\:block {
    display: block;
  }
}

@media only screen and (min-width: 1920px) {
  #pdp-cplus .xl2\:hidden {
    display: none;
  }

  #pdp-cplus .xl2\:w-full {
    width: 100%;
  }

  #pdp-cplus .xl2\:w-1\/2 {
    width: 50%;
  }

  #pdp-cplus .xl2\:w-auto {
    width: auto;
  }

  #pdp-cplus .xl2\:h-full {
    height: 100%;
  }

  #pdp-cplus .xl2\:h-auto {
    height: auto;
  }

  #pdp-cplus .xl2\:block {
    display: block;
  }
}

#pdp-cplus .z-10 {
  z-index: 10;
}

#pdp-cplus .z-10-important {
  z-index: 10 !important;
}

#pdp-cplus .z-20 {
  z-index: 20;
}

#pdp-cplus .z-20-important {
  z-index: 20 !important;
}

#pdp-cplus .z-30 {
  z-index: 30;
}

#pdp-cplus .z-30-important {
  z-index: 30 !important;
}

#pdp-cplus .z-40 {
  z-index: 40;
}

#pdp-cplus .z-40-important {
  z-index: 40 !important;
}

#pdp-cplus .z-50 {
  z-index: 50;
}

#pdp-cplus .z-50-important {
  z-index: 50 !important;
}

#pdp-cplus .z-60 {
  z-index: 60;
}

#pdp-cplus .z-60-important {
  z-index: 60 !important;
}

#pdp-cplus .z-70 {
  z-index: 70;
}

#pdp-cplus .z-70-important {
  z-index: 70 !important;
}

#pdp-cplus .z-80 {
  z-index: 80;
}

#pdp-cplus .z-80-important {
  z-index: 80 !important;
}

#pdp-cplus .z-90 {
  z-index: 90;
}

#pdp-cplus .z-90-important {
  z-index: 90 !important;
}

#pdp-cplus .z-100 {
  z-index: 100;
}

#pdp-cplus .z-100-important {
  z-index: 100 !important;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .sm\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .sm\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .sm\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .sm\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .sm\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .sm\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .sm\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .sm\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .sm\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .sm\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .sm\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .sm\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .sm\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .sm\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .sm\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .sm\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .sm\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .sm\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .sm\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .sm\:z-100-important {
    z-index: 100 !important;
  }
}

@media only screen and (min-width: 768px) {
  #pdp-cplus .md\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .md\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .md\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .md\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .md\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .md\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .md\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .md\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .md\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .md\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .md\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .md\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .md\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .md\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .md\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .md\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .md\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .md\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .md\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .md\:z-100-important {
    z-index: 100 !important;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .lg\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .lg\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .lg\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .lg\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .lg\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .lg\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .lg\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .lg\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .lg\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .lg\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .lg\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .lg\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .lg\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .lg\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .lg\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .lg\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .lg\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .lg\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .lg\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .lg\:z-100-important {
    z-index: 100 !important;
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus .xlg\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .xlg\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .xlg\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .xlg\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .xlg\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .xlg\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .xlg\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .xlg\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .xlg\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .xlg\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .xlg\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .xlg\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .xlg\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .xlg\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .xlg\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .xlg\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .xlg\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .xlg\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .xlg\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .xlg\:z-100-important {
    z-index: 100 !important;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .xl\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .xl\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .xl\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .xl\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .xl\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .xl\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .xl\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .xl\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .xl\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .xl\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .xl\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .xl\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .xl\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .xl\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .xl\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .xl\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .xl\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .xl\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .xl\:z-100-important {
    z-index: 100 !important;
  }
}

@media only screen and (min-width: 1920px) {
  #pdp-cplus .xl2\:z-10 {
    z-index: 10;
  }

  #pdp-cplus .xl2\:z-10-important {
    z-index: 10 !important;
  }

  #pdp-cplus .xl2\:z-20 {
    z-index: 20;
  }

  #pdp-cplus .xl2\:z-20-important {
    z-index: 20 !important;
  }

  #pdp-cplus .xl2\:z-30 {
    z-index: 30;
  }

  #pdp-cplus .xl2\:z-30-important {
    z-index: 30 !important;
  }

  #pdp-cplus .xl2\:z-40 {
    z-index: 40;
  }

  #pdp-cplus .xl2\:z-40-important {
    z-index: 40 !important;
  }

  #pdp-cplus .xl2\:z-50 {
    z-index: 50;
  }

  #pdp-cplus .xl2\:z-50-important {
    z-index: 50 !important;
  }

  #pdp-cplus .xl2\:z-60 {
    z-index: 60;
  }

  #pdp-cplus .xl2\:z-60-important {
    z-index: 60 !important;
  }

  #pdp-cplus .xl2\:z-70 {
    z-index: 70;
  }

  #pdp-cplus .xl2\:z-70-important {
    z-index: 70 !important;
  }

  #pdp-cplus .xl2\:z-80 {
    z-index: 80;
  }

  #pdp-cplus .xl2\:z-80-important {
    z-index: 80 !important;
  }

  #pdp-cplus .xl2\:z-90 {
    z-index: 90;
  }

  #pdp-cplus .xl2\:z-90-important {
    z-index: 90 !important;
  }

  #pdp-cplus .xl2\:z-100 {
    z-index: 100;
  }

  #pdp-cplus .xl2\:z-100-important {
    z-index: 100 !important;
  }
}

#pdp-cplus .-z-10 {
  z-index: -10;
}

#pdp-cplus .-z-10-important {
  z-index: -10 !important;
}

#pdp-cplus .visually-hidden {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

#pdp-cplus .vertical-center {
  display: table;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

#pdp-cplus .vertical-center-content {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

#pdp-cplus .vertical-center-content .grid {
  height: auto;
}

#pdp-cplus .break-word {
  word-break: break-word;
}

#pdp-cplus .vertically-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#pdp-cplus .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#pdp-cplus .current-color {
  color: currentColor;
}

#pdp-cplus .hidden-overflow {
  overflow: hidden;
}

#pdp-cplus .full-height-relative {
  position: relative;
  height: 100%;
}

#pdp-cplus .pull-left {
  float: left;
}

#pdp-cplus .clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

#pdp-cplus .clearfix {
  clear: both;
  display: block;
}

#pdp-cplus .browserupgrade {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 90%;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 5%;
  background: #fff;
}

#pdp-cplus .browserupgrade a {
  text-decoration: underline;
}

#pdp-cplus .browserupgrade strong {
  font-weight: 700;
}

#pdp-cplus .inlineBlockFix {
  font-size: 0;
}

#pdp-cplus .inlineBlockFix > * {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  #pdp-cplus .sm-hidden.sm-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  #pdp-cplus .sm-visible.sm-visible {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  #pdp-cplus .mobile-hidden.mobile-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 1441px) {
  #pdp-cplus .max-xlg-break {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  #pdp-cplus .mobile-visible.mobile-visible {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #pdp-cplus .tablet-desktop-hidden.tablet-desktop-hidden {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  #pdp-cplus .desktop-visible.desktop-visible {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  #pdp-cplus .desktop-hidden.desktop-hidden {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  #pdp-cplus .desktop-air-visible.desktop-air-visible {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  #pdp-cplus .phone-hidden.phone-hidden {
    display: none;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl-hidden {
    display: none !important;
  }
}

#pdp-cplus .tablet-only-visible {
  display: none;
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
  #pdp-cplus .tablet-only-visible {
    display: block;
  }
}

#pdp-cplus .phone-only-visible {
  display: block;
}

@media screen and (min-width: 601px) {
  #pdp-cplus .phone-only-visible {
    display: none !important;
  }
}

@media screen and (min-width: 375px) {
  #pdp-cplus .mobile-middle-only-visible {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  #pdp-cplus .mobile-hidden-xs {
    display: none !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #pdp-cplus .landscape-video-container {
    padding-top: 0 !important;
  }

  #pdp-cplus .landscape-video {
    display: block !important;
  }
}

#pdp-cplus .hide-element {
  display: none !important;
  visibility: hidden !important;
}

#pdp-cplus .sub-body-container {
  min-height: 100%;
}

#pdp-cplus .no-gutter-left {
  padding-left: 0 !important;
}

#pdp-cplus .no-margin-left {
  margin-left: 0 !important;
}

#pdp-cplus .no-margin-top {
  margin-top: 0 !important;
}

#pdp-cplus .border-red {
  border: 1px solid red !important;
}

#pdp-cplus .display-none {
  display: none;
}

#pdp-cplus .padding-top-medium {
  padding-top: 15px !important;
}

#pdp-cplus .inline-block {
  display: inline-block !important;
}

#pdp-cplus .float-none {
  float: none !important;
}

#pdp-cplus .hover-fade:hover {
  opacity: 0.95;
}

#pdp-cplus .full-width-height {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

#pdp-cplus .border-radius {
  border-radius: 6px;
}

#pdp-cplus .popup-is-in-view {
  overflow: hidden;
}

#pdp-cplus .border-gray {
  border-color: #7c7d81 !important;
}

#pdp-cplus .full-max-width {
  max-width: 100% !important;
}

#pdp-cplus .margin-x-auto {
  margin-right: auto;
  margin-left: auto;
}

#pdp-cplus .bg-color-gray {
  background-color: #edf0f0;
}

#pdp-cplus .full-box-width {
  width: 100%;
  box-sizing: border-box;
}

#pdp-cplus .no-width {
  width: 0;
}

#pdp-cplus .color-gray {
  color: #7c7d81 !important;
}

#pdp-cplus .position-relative {
  position: relative;
}

#pdp-cplus .no-margin-bottom {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 800px) {
  #pdp-cplus .no-margin-bottom-mobile {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  #pdp-cplus .no-padding-bottom-mobile {
    padding-bottom: 0 !important;
  }
}

#pdp-cplus .text-smaller {
  font-size: 12px !important;
  line-height: 16px !important;
}

@media screen and (max-width: 320px) {
  #pdp-cplus .text-smaller-mobile-xs {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

#pdp-cplus .shifted-down-sm {
  position: relative;
  top: 8px;
}

@media screen and (max-width: 320px) {
  #pdp-cplus .mobile-xs-hidden {
    display: none !important;
  }
}

#pdp-cplus .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

#pdp-cplus .img-responsive--watty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#pdp-cplus .card-box-shadow {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: hidden;
}

#pdp-cplus .no-padding.no-padding {
  padding: 0;
}

#pdp-cplus picture {
  transition: all 0.15s ease-in-out;
}

#pdp-cplus picture.lazy-load-picture-blurred {
  filter: blur(12px);
  -webkit-filter: blur(12px);
  will-change: auto;
  background: #fff;
  overflow: hidden;
}

#pdp-cplus picture.lazy-load-picture-blurred img {
  transform: scale(1.03);
  filter: blur(12px);
  overflow: hidden;
}

#pdp-cplus picture.lazy-load-picture-fadein img {
  opacity: 0;
}

#pdp-cplus picture img {
  transition: all 0.2s linear 0.1s;
}

#pdp-cplus .flex {
  display: flex;
}

#pdp-cplus .inline-flex {
  display: inline-flex;
}

#pdp-cplus .flex-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#pdp-cplus .flex-column {
  flex-direction: column;
}

#pdp-cplus .flex-wrap {
  flex-wrap: wrap;
}

#pdp-cplus .overflow-hidden {
  overflow: hidden;
}

#pdp-cplus .cursor-pointer {
  cursor: pointer;
}

#pdp-cplus .cursor-auto {
  cursor: auto;
}

#pdp-cplus .space-between {
  justify-content: space-between;
}

#pdp-cplus .corner-radius-label {
  position: absolute;
  border-radius: 12px 0;
  z-index: 2;
  top: -1px;
  left: -1px;
  padding: 9px 16px 8px 17px;
}

#pdp-cplus .corner-radius-label--grid {
  border-radius: 6px 0;
}

#pdp-cplus .corner-radius-label--promo {
  border: 1px solid;
  border-radius: 12px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
}

#pdp-cplus .tile-label-group {
  position: absolute;
  z-index: 2;
  top: -1px;
  right: -1px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 140px;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .tile-label-group {
    max-width: 100%;
  }
}

#pdp-cplus .rounded-2 {
  border-radius: 2px;
}

#pdp-cplus .bradius-safari-hack {
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}

#pdp-cplus .box-border {
  box-sizing: border-box;
}

#pdp-cplus .background-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  pointer-events: all;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  pointer-events: none;
}

#pdp-cplus .static.static {
  position: static;
}

#pdp-cplus .relative.relative {
  position: relative;
}

#pdp-cplus .absolute.absolute {
  position: absolute;
}

#pdp-cplus .fixed.fixed {
  position: fixed;
}

#pdp-cplus .touch-none {
  touch-action: none;
}

#pdp-cplus .grecaptcha-badge {
  visibility: hidden;
}

#pdp-cplus .line-through {
  text-decoration: line-through;
  font-weight: 400;
  font-style: italic;
}

#pdp-cplus .heading-label {
  position: relative;
  top: -6px;
}

#pdp-cplus .inline-wrapper {
  display: flex;
  align-items: center;
}

#pdp-cplus .price-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 8px;
}

#pdp-cplus .price-wrapper__info-container {
  max-width: 160px;
}

#pdp-cplus .price-wrapper ul > li {
  justify-content: flex-start;
}

#pdp-cplus .controls-desktop {
  border-radius: 0 0 12px 12px;
}

#pdp-cplus .controls-desktop .shopping-buttons-wrapper {
  display: flex;
}

#pdp-cplus .color-tooltip {
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  box-shadow: 0 0 0 1px #d7dadb;
  position: absolute;
  width: 160px;
  height: 160px;
  top: -168.5px;
  left: -63px;
  overflow: hidden;
  border-radius: 100px;
  pointer-events: none;
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .color-tooltip {
    display: flex;
  }
}

#pdp-cplus .color-tooltip__apla {
  width: 100%;
  position: relative;
  margin-top: 118px;
  min-height: 42px;
  max-width: 160px;
  white-space: normal;
}

#pdp-cplus .color-tooltip__image {
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  min-width: calc(100% + 2px);
  min-height: calc(100% + 2px);
}

#pdp-cplus .color-tooltip__picture {
  transition: none;
}

#pdp-cplus .color-tooltip__hover-trigger {
  position: relative;
}

#pdp-cplus .color-tooltip__hover-trigger:hover .color-tooltip {
  opacity: 1;
  visibility: visible;
}

#pdp-cplus .colors-wrapper {
  max-width: 100%;
}

#pdp-cplus .colors-wrapper--chessboard li:nth-child(2n) {
  margin-top: 36px;
  margin-left: -4px;
  margin-right: -4px;
}

#pdp-cplus .colors-wrapper .title-break {
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translate(-50%, -100%);
  line-height: 1;
  pointer-events: none;
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .colors-wrapper .title-break {
    position: static;
    transform: none;
  }
}

#pdp-cplus .price-wrapper__price-container {
  flex: 1;
  width: auto;
}

#pdp-cplus .price-wrapper__info-container {
  width: auto;
}

#pdp-cplus .button-disable {
  background: #edf0f0 !important;
  border-color: #edf0f0 !important;
  color: #d7dadb !important;
  cursor: pointer;
  pointer-events: none;
}

#pdp-cplus .button-disable .button-wrapper {
  color: #d7dadb !important;
}

#pdp-cplus .button-disable svg path {
  fill: #d7dadb !important;
}

#pdp-cplus .scroll-down {
  position: absolute;
  bottom: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

#pdp-cplus .scroll-down__paragraph {
  transition: color 0.2s ease;
}

#pdp-cplus .scroll-down svg {
  transform: translateY(0);
  margin-top: 0;
  transition: transform 0.2s ease;
}

#pdp-cplus .scroll-down svg path {
  transition: fill 0.2s ease;
}

#pdp-cplus .scroll-down:hover .scroll-down__paragraph {
  color: #0d646f;
}

#pdp-cplus .scroll-down:hover svg {
  transform: translateY(4px);
}

#pdp-cplus .scroll-down:hover svg path {
  fill: #0d646f;
}

#pdp-cplus .fade-enter-active,
#pdp-cplus .fade-leave-active {
  transition: opacity 0.1s ease-in;
}

#pdp-cplus .fade-enter,
#pdp-cplus .fade-leave-to {
  opacity: 0;
}

#pdp-cplus .fade-svg-enter-active,
#pdp-cplus .fade-svg-leave-active {
  transition: opacity 0.2s;
  transition: opacity 0.1s;
}

#pdp-cplus .fade-svg-enter,
#pdp-cplus .fade-svg-leave-to {
  opacity: 0;
}

#pdp-cplus .adjust-buttons-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  touch-action: none;
  pointer-events: none;
}

#pdp-cplus .adjust-buttons-wrapper .control-btn {
  position: absolute;
}

#pdp-cplus .adjust-buttons-wrapper > * {
  touch-action: auto;
  pointer-events: auto;
}

#pdp-cplus .card {
  padding: 24px;
  border-radius: 12px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}

#pdp-cplus .tylko-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#pdp-cplus .tylko-cell .text {
  margin-bottom: 0;
}

#pdp-cplus .miniature {
  display: flex;
}

#pdp-cplus .tylko-tabs-container {
  position: relative;
  overflow: hidden;
  margin-top: -10px;
}

#pdp-cplus .tylko-tabs-container .shadow {
  position: absolute;
  width: 10px;
  height: 36px;
  top: 2px;
  bottom: 0;
  z-index: 1;
  transition: opacity 0.3s ease;
}

#pdp-cplus .tylko-tabs-container .shadow.left {
  opacity: 0;
  left: 0;
  background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
}

#pdp-cplus .tylko-tabs-container .shadow.right {
  opacity: 1;
  right: 0;
  background: linear-gradient(270deg, #fff 0, hsla(0, 0%, 100%, 0));
}

#pdp-cplus .tylko-tabs-wrapper {
  display: block;
  text-align: left;
  padding-bottom: 10px;
  transform: translateY(10px);
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: auto;
  -webkit-overflow-scrolling: touch;
}

#pdp-cplus .tylko-tabs-wrapper > * {
  display: inline-block;
}

#pdp-cplus .tylko-tabs-wrapper::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

#pdp-cplus .tooltip-slide-enter-active,
#pdp-cplus .tooltip-slide-leave-active {
  transition: all 0.3s ease;
}

#pdp-cplus .tooltip-slide-enter,
#pdp-cplus .tooltip-slide-leave-to {
  opacity: 0;
  top: 40px;
}

#pdp-cplus .configurator-mobile-card {
  padding: 8px 0 16px !important;
  border-radius: 0 !important;
}

#pdp-cplus .configurator-mobile-card .tylko-tabs-wrapper {
  padding-left: 8px !important;
}

#pdp-cplus .controls {
  position: relative;
  z-index: 1;
}

#pdp-cplus .controls .containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#pdp-cplus .configuration-wrapper {
  margin-bottom: 0 !important;
}

#pdp-cplus .save-for-later-wrapper .save-for-later {
  width: 100%;
}

#pdp-cplus .plinth-info .foo {
  flex-direction: column;
  align-items: center;
}

#pdp-cplus .dna-switcher {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 8px;
}

#pdp-cplus .dna-switcher--3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#pdp-cplus .dna-switcher .dna-wrapper button {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #edf0f0;
  border-color: #edf0f0;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

#pdp-cplus .dna-switcher .dna-wrapper button.active .dna-wrapper__text,
#pdp-cplus .dna-switcher .dna-wrapper button:hover .dna-wrapper__text {
  color: #0d646f;
}

#pdp-cplus .dna-switcher .dna-wrapper button.active path,
#pdp-cplus .dna-switcher .dna-wrapper button:hover path {
  fill: #0d646f;
}

#pdp-cplus .dna-switcher .dna-wrapper button.active {
  background-color: #fff;
  border-color: #cad0d0;
}

#pdp-cplus .dna-switcher .dna-wrapper button.active:hover {
  border-color: #09f;
}

#pdp-cplus .dna-switcher .dna-wrapper .dna-wrapper__text {
  text-transform: capitalize;
}

#pdp-cplus .stepper-caption {
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#pdp-cplus :export {
  dserror: #ff0037;
  dsblack: #000;
  dsgrey800: #cad0d0;
}

#pdp-cplus body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-feature-settings: "liga" off;
  font-feature-settings: "dlig" off;
  font-feature-settings: "tnum" off;
  font-feature-settings: "onum" off;
  font-feature-settings: "ss01" off;
}

#pdp-cplus .text-center {
  text-align: center;
}

#pdp-cplus .text-left {
  text-align: left;
}

#pdp-cplus .text-right {
  text-align: right;
}

#pdp-cplus .text-nowrap {
  white-space: nowrap;
}

#pdp-cplus .text-uppercase#pdp-cplus .text-uppercase {
  text-transform: uppercase;
}

#pdp-cplus .text-capitalize#pdp-cplus .text-capitalize {
  text-transform: capitalize;
}

#pdp-cplus .text-underline {
  text-decoration: underline;
}

#pdp-cplus .text-no-underline {
  text-decoration: none;
}

#pdp-cplus .btn-link.capitalize-first-letter,
#pdp-cplus .text-capitalize-first-letter {
  text-transform: lowercase;
}

#pdp-cplus .btn-link.capitalize-first-letter:first-letter,
#pdp-cplus .text-capitalize-first-letter:first-letter {
  text-transform: uppercase;
}

#pdp-cplus .one-line {
  height: 18px;
  overflow: hidden;
  display: inline-block;
  width: 222px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 0;
}

#pdp-cplus .one-line:after {
  content: "...";
}

#pdp-cplus .text-lowercase {
  text-transform: lowercase;
}

#pdp-cplus .text-bold {
  font-weight: 700;
}

#pdp-cplus .normal-9 {
  font-weight: 400;
}

#pdp-cplus .bold-9,
#pdp-cplus .normal-9 {
  font-size: 9px;
  line-height: 1.4;
  letter-spacing: 0;
}

#pdp-cplus .bold-9 {
  font-weight: 700;
}

#pdp-cplus .normal-10 {
  font-weight: 400;
}

#pdp-cplus .bold-10,
#pdp-cplus .normal-10 {
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0;
}

#pdp-cplus .bold-10 {
  font-weight: 700;
}

#pdp-cplus .bold-10-2 {
  font-weight: 700;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

#pdp-cplus .normal-12,
#pdp-cplus .normal-12-2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0;
}

#pdp-cplus .normal-12-2 {
  text-transform: uppercase;
}

#pdp-cplus .bold-12 {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0;
}

#pdp-cplus .bold-12-2,
#pdp-cplus .c-tab-button {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

#pdp-cplus .bold-13,
#pdp-cplus .c-button,
#pdp-cplus .noUi-tooltip,
#pdp-cplus .toggle-group .overlay,
#pdp-cplus .toggle-group .toggle-button,
.toggle-group #pdp-cplus .overlay,
.toggle-group #pdp-cplus .toggle-button {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.3;
}

#pdp-cplus .normal-14 {
  font-weight: 400;
}

#pdp-cplus .bold-14,
#pdp-cplus .normal-14 {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0;
}

#pdp-cplus .bold-14 {
  font-weight: 700;
}

#pdp-cplus .bold-14-2,
#pdp-cplus .cta-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

#pdp-cplus .light-16 {
  font-weight: 300;
}

#pdp-cplus .light-16,
#pdp-cplus .normal-16 {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.2px;
}

#pdp-cplus .normal-16 {
  font-weight: 400;
}

#pdp-cplus .bold-16 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
}

#pdp-cplus .normal-16-2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

#pdp-cplus .normal-20 {
  font-weight: 400;
}

#pdp-cplus .bold-20,
#pdp-cplus .normal-20 {
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -0.3px;
}

#pdp-cplus .bold-20 {
  font-weight: 700;
}

#pdp-cplus .light-24 {
  font-weight: 300;
}

#pdp-cplus .light-24,
#pdp-cplus .normal-24 {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.3px;
}

#pdp-cplus .normal-24 {
  font-weight: 400;
}

#pdp-cplus .bold-24 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.3px;
}

#pdp-cplus .normal-28 {
  font-weight: 400;
}

#pdp-cplus .bold-28,
#pdp-cplus .normal-28 {
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

#pdp-cplus .bold-28 {
  font-weight: 700;
}

#pdp-cplus .normal-32 {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -1.5px;
}

#pdp-cplus .bold-32 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

#pdp-cplus .normal-42 {
  font-weight: 400;
  font-size: 42px;
  line-height: 1.1;
  letter-spacing: -1.5px;
}

#pdp-cplus .bold-46 {
  font-weight: 700;
  font-size: 46px;
  line-height: 1.1;
  letter-spacing: -1.5px;
}

#pdp-cplus .bold-54 {
  font-weight: 700;
  font-size: 54px;
  line-height: 1.05;
  letter-spacing: -1.5px;
}

#pdp-cplus .bold-72 {
  font-weight: 700;
  font-size: 72px;
  line-height: 1.1;
  letter-spacing: -1px;
}

#pdp-cplus .bold-88 {
  font-weight: 700;
  font-size: 88px;
  line-height: 0.95;
  letter-spacing: -3.5px;
}

@media only screen and (min-width: 375px) {
  #pdp-cplus .sm\:text-center {
    text-align: center;
  }

  #pdp-cplus .sm\:text-left {
    text-align: left;
  }

  #pdp-cplus .sm\:text-right {
    text-align: right;
  }

  #pdp-cplus .sm\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .sm\:normal-12,
  #pdp-cplus .sm\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .sm\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .sm\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .sm\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .sm\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .sm\:light-16 {
    font-weight: 300;
  }

  #pdp-cplus .sm\:light-16,
  #pdp-cplus .sm\:normal-16 {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .sm\:normal-16 {
    font-weight: 400;
  }

  #pdp-cplus .sm\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .sm\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .sm\:normal-20 {
    font-weight: 400;
  }

  #pdp-cplus .sm\:bold-20,
  #pdp-cplus .sm\:normal-20 {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .sm\:bold-20 {
    font-weight: 700;
  }

  #pdp-cplus .sm\:light-24 {
    font-weight: 300;
  }

  #pdp-cplus .sm\:light-24,
  #pdp-cplus .sm\:normal-24 {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .sm\:normal-24 {
    font-weight: 400;
  }

  #pdp-cplus .sm\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .sm\:normal-28 {
    font-weight: 400;
  }

  #pdp-cplus .sm\:bold-28,
  #pdp-cplus .sm\:normal-28 {
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .sm\:bold-28 {
    font-weight: 700;
  }

  #pdp-cplus .sm\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .sm\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .sm\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .sm\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .sm\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .sm\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .sm\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

@media only screen and (min-width: 768px) {
  #pdp-cplus .md\:text-center {
    text-align: center;
  }

  #pdp-cplus .md\:text-left {
    text-align: left;
  }

  #pdp-cplus .md\:text-right {
    text-align: right;
  }

  #pdp-cplus .md\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .md\:normal-12,
  #pdp-cplus .md\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .md\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .md\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .md\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .md\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .md\:light-16 {
    font-weight: 300;
  }

  #pdp-cplus .md\:light-16,
  #pdp-cplus .md\:normal-16 {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .md\:normal-16 {
    font-weight: 400;
  }

  #pdp-cplus .md\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .md\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .md\:normal-20 {
    font-weight: 400;
  }

  #pdp-cplus .md\:bold-20,
  #pdp-cplus .md\:normal-20 {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .md\:bold-20 {
    font-weight: 700;
  }

  #pdp-cplus .md\:light-24 {
    font-weight: 300;
  }

  #pdp-cplus .md\:light-24,
  #pdp-cplus .md\:normal-24 {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .md\:normal-24 {
    font-weight: 400;
  }

  #pdp-cplus .md\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .md\:normal-28 {
    font-weight: 400;
  }

  #pdp-cplus .md\:bold-28,
  #pdp-cplus .md\:normal-28 {
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .md\:bold-28 {
    font-weight: 700;
  }

  #pdp-cplus .md\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .md\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .md\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .md\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .md\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .md\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .md\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .lg\:text-center {
    text-align: center;
  }

  #pdp-cplus .lg\:text-left {
    text-align: left;
  }

  #pdp-cplus .lg\:text-right {
    text-align: right;
  }

  #pdp-cplus .lg\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .lg\:normal-12,
  #pdp-cplus .lg\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .lg\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .lg\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .lg\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .lg\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .lg\:light-16 {
    font-weight: 300;
  }

  #pdp-cplus .lg\:light-16,
  #pdp-cplus .lg\:normal-16 {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .lg\:normal-16 {
    font-weight: 400;
  }

  #pdp-cplus .lg\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .lg\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .lg\:normal-20 {
    font-weight: 400;
  }

  #pdp-cplus .lg\:bold-20,
  #pdp-cplus .lg\:normal-20 {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .lg\:bold-20 {
    font-weight: 700;
  }

  #pdp-cplus .lg\:light-24 {
    font-weight: 300;
  }

  #pdp-cplus .lg\:light-24,
  #pdp-cplus .lg\:normal-24 {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .lg\:normal-24 {
    font-weight: 400;
  }

  #pdp-cplus .lg\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .lg\:normal-28 {
    font-weight: 400;
  }

  #pdp-cplus .lg\:bold-28,
  #pdp-cplus .lg\:normal-28 {
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .lg\:bold-28 {
    font-weight: 700;
  }

  #pdp-cplus .lg\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .lg\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .lg\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .lg\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .lg\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .lg\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .lg\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus .xlg\:text-center {
    text-align: center;
  }

  #pdp-cplus .xlg\:text-left {
    text-align: left;
  }

  #pdp-cplus .xlg\:text-right {
    text-align: right;
  }

  #pdp-cplus .xlg\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xlg\:normal-12,
  #pdp-cplus .xlg\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .xlg\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xlg\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .xlg\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xlg\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xlg\:light-16 {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xlg\:normal-16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xlg\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xlg\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xlg\:normal-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xlg\:bold-20 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xlg\:light-24 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xlg\:normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xlg\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xlg\:normal-28 {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xlg\:bold-28 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xlg\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xlg\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xlg\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xlg\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xlg\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xlg\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .xlg\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus .xl\:text-center {
    text-align: center;
  }

  #pdp-cplus .xl\:text-left {
    text-align: left;
  }

  #pdp-cplus .xl\:text-right {
    text-align: right;
  }

  #pdp-cplus .xl\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl\:normal-12,
  #pdp-cplus .xl\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .xl\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .xl\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl\:light-16 {
    font-weight: 300;
  }

  #pdp-cplus .xl\:light-16,
  #pdp-cplus .xl\:normal-16 {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xl\:normal-16 {
    font-weight: 400;
  }

  #pdp-cplus .xl\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xl\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl\:normal-20 {
    font-weight: 400;
  }

  #pdp-cplus .xl\:bold-20,
  #pdp-cplus .xl\:normal-20 {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl\:bold-20 {
    font-weight: 700;
  }

  #pdp-cplus .xl\:light-24 {
    font-weight: 300;
  }

  #pdp-cplus .xl\:light-24,
  #pdp-cplus .xl\:normal-24 {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl\:normal-24 {
    font-weight: 400;
  }

  #pdp-cplus .xl\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl\:normal-28 {
    font-weight: 400;
  }

  #pdp-cplus .xl\:bold-28,
  #pdp-cplus .xl\:normal-28 {
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xl\:bold-28 {
    font-weight: 700;
  }

  #pdp-cplus .xl\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xl\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .xl\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

@media only screen and (min-width: 1920px) {
  #pdp-cplus .xl2\:text-center {
    text-align: center;
  }

  #pdp-cplus .xl2\:text-left {
    text-align: left;
  }

  #pdp-cplus .xl2\:text-right {
    text-align: right;
  }

  #pdp-cplus .xl2\:normal-9 {
    font-weight: 400;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-9 {
    font-weight: 700;
    font-size: 9px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:normal-10 {
    font-weight: 400;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-10 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-10-2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl2\:normal-12,
  #pdp-cplus .xl2\:normal-12-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:normal-12-2 {
    text-transform: uppercase;
  }

  #pdp-cplus .xl2\:bold-12 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-12-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl2\:bold-13 {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.3;
  }

  #pdp-cplus .xl2\:normal-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  #pdp-cplus .xl2\:bold-14-2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl2\:light-16 {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xl2\:normal-16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xl2\:bold-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
  }

  #pdp-cplus .xl2\:normal-16-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  #pdp-cplus .xl2\:normal-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl2\:bold-20 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl2\:light-24 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl2\:normal-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl2\:bold-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: -0.3px;
  }

  #pdp-cplus .xl2\:normal-28 {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xl2\:bold-28 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xl2\:normal-32 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl2\:bold-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.5px;
  }

  #pdp-cplus .xl2\:normal-42 {
    font-weight: 400;
    font-size: 42px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl2\:bold-46 {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl2\:bold-54 {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.05;
    letter-spacing: -1.5px;
  }

  #pdp-cplus .xl2\:bold-72 {
    font-weight: 700;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -1px;
  }

  #pdp-cplus .xl2\:bold-88 {
    font-weight: 700;
    font-size: 88px;
    line-height: 0.95;
    letter-spacing: -3.5px;
  }
}

#pdp-cplus .price-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#pdp-cplus .price-container--loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#pdp-cplus .cplus-crossed-out-price .percentage,
#pdp-cplus .old-configurator-crossed-out-price .percentage {
  border: 1px solid;
  border-radius: 50px;
}

#pdp-cplus .cplus-crossed-out-price .regular-price,
#pdp-cplus .old-configurator-crossed-out-price .regular-price {
  position: relative;
}

#pdp-cplus .cplus-crossed-out-price .regular-price:before,
#pdp-cplus .old-configurator-crossed-out-price .regular-price:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #484444;
  border-radius: 2px;
  top: 50%;
  left: 0;
  margin-top: -1px;
}

#pdp-cplus .price-with-discount--breaked {
  display: block;
}

#pdp-cplus .cplus-crossed-out-price {
  align-items: flex-end;
  align-items: center;
  width: auto !important;
}

@media only screen and (max-width: 1023px) {
  #pdp-cplus .cplus-crossed-out-price .percentage--in-one-line {
    margin-top: -2px;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .cplus-crossed-out-price .percentage--in-one-line {
    margin-bottom: -5px;
  }
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .cplus-crossed-out-price .regular-price--in-one-line {
    margin-bottom: -6px;
  }
}

#pdp-cplus .old-configurator-crossed-out-price {
  display: flex;
  flex-wrap: wrap;
  line-height: normal !important;
  height: auto !important;
  font-size: medium !important;
}

@media only screen and (max-width: 1023px) {
  #pdp-cplus .old-configurator-crossed-out-price {
    justify-content: flex-end;
    padding-right: 8px;
  }
}

#pdp-cplus .old-configurator-crossed-out-price .percentage {
  flex-shrink: 2;
}

#pdp-cplus .old-configurator-crossed-out-price .regular-price {
  flex-shrink: 2;
  display: inline-block;
  transform: translateY(1px);
}

#pdp-cplus .old-configurator-crossed-out-price .price-width-discount {
  display: flex;
  flex-basis: 100%;
}

@media only screen and (max-width: 1023px) {
  #pdp-cplus .old-configurator-crossed-out-price .price-width-discount {
    justify-content: flex-end;
  }
}

#pdp-cplus .crossed-out-price form#custom-form {
  padding-top: 6px !important;
}

#pdp-cplus .crossed-out-price .custom-gui__top {
  display: flex !important;
  align-items: flex-end;
  margin-bottom: 12px !important;
}

#pdp-cplus #pdp-emily .crossed-out-price #custom-form.promoslot-2018 .custom-gui__top {
  margin-bottom: 12px !important;
}

@keyframes shiny-self {
  0% {
    opacity: 0;
    right: -100%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    right: 30%;
  }
}

.configurator.configurator-loader {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  transition: opacity 0.1s ease;
  z-index: 10;
  display: block;
  margin-top: -46px;
  padding-top: 1px;
}

.configurator.configurator-loader .content-wrapper {
  border-radius: 6px;
}

.configurator.configurator-loader .content-wrapper.grid-img-wrapper {
  background-size: cover;
}

.configurator.configurator-loader .image-wrapper {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border: 1px solid #cad0d0;
  border-radius: 6px;
}

@media only screen and (min-width: 1024px) {
  .configurator.configurator-loader .image-wrapper {
    width: 300px;
    height: 300px;
  }
}

.configurator.configurator-loader .image-wrapper .gradient-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.configurator.configurator-loader .image-wrapper .gradient-wrapper__svg {
  opacity: 0;
  position: absolute;
  mix-blend-mode: overlay;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 100%;
  animation: shiny-self 1.3s ease-in-out infinite;
}

@media only screen and (max-width: 1023px) {
  .configurator.configurator-loader .image-wrapper .gradient-wrapper__svg {
    width: 100px;
  }
}

.configurator.configurator-loader .image-wrapper .grid-img {
  position: absolute;
  left: 0;
  right: 0;
}

.configurator.configurator-loader .shelf-info {
  width: 400px;
}

.configurator.configurator-loader .shelf-info__separator {
  position: relative;
}

.configurator.configurator-loader .shelf-info__separator:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 1px;
  background: #cad0d0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.configurator.configurator-loader .skeleton-wrapper {
  margin-left: 0;
}

@media only screen and (min-width: 768px) {
  .configurator.configurator-loader .skeleton-wrapper {
    position: absolute;
    right: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .configurator.configurator-loader .skeleton-wrapper {
    right: 32px;
  }
}

@media only screen and (min-width: 1440px) {
  .configurator.configurator-loader .skeleton-wrapper {
    right: 74px;
  }
}

.configurator.configurator-loader .skeleton-wrapper .skeleton {
  border-radius: 0 0 12px 12px;
}

.configurator.configurator-loader .skeleton-wrapper .skeleton__body {
  border-bottom: 1px solid #cad0d0;
}

.configurator.configurator-loader .skeleton-wrapper .price-wrapper {
  border-bottom: 1px solid #cad0d0;
  justify-content: space-between;
}

.configurator.configurator-loader .skeleton-wrapper .price-wrapper__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.configurator.configurator-loader .skeleton-wrapper__img {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .configurator.configurator-loader {
    display: flex;
    z-index: 10;
    margin-top: 0;
    padding-top: 0;
  }

  .configurator.configurator-loader .skeleton-wrapper {
    margin-left: 0;
  }

  .configurator.configurator-loader .skeleton-wrapper__img {
    width: 100%;
  }

  .configurator.configurator-loader .configurator-loader-container {
    margin-top: 0;
    height: auto;
  }
}

.configurator.configurator-loader .price-wrapper-mobile {
  margin-top: 46px;
}

.configurator .configurator-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
  height: 100vw;
}

@media only screen and (min-width: 1024px) {
  .configurator .configurator-loader-container {
    width: calc(100% - 377px);
    height: calc(74vw - 288.98px);
  }
}

@media only screen and (min-width: 1280px) {
  .configurator .configurator-loader-container {
    width: calc(100% - 393px);
    height: calc(58vw - 236.94px);
  }
}

@media only screen and (min-width: 1440px) {
  .configurator .configurator-loader-container {
    width: calc(100% - 462px);
    height: calc(63vw - 301.06px);
  }
}

@media only screen and (min-width: 1680px) {
  .configurator .configurator-loader-container {
    width: calc(100% - 462px);
    height: calc(56vw - 268.72px);
  }
}

.configurator .skeleton-wrapper__svg {
  width: 8px;
  height: 8px;
  display: block;
  margin-top: 1px;
}

.tylko-snackbar {
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  z-index: 9;
  bottom: 40px;
  border-radius: 6px;
  width: calc(100% - 48px);
  transition: opacity 0.3s ease-out;
}

@media only screen and (min-width: 1024px) {
  .tylko-snackbar {
    position: absolute;
    bottom: auto;
    top: 16px;
    border-radius: 30px;
    width: auto;
  }
}

.tylko-snackbar__close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .tylko-snackbar__close {
    position: relative;
    top: auto;
    right: auto;
  }
}

.tylko-snackbar__close:after,
.tylko-snackbar__close:before {
  content: "";
  background: #7c7d81;
  position: absolute;
  width: 14px;
  height: 1px;
  left: 3.5px;
  top: 9px;
}

.tylko-snackbar__close:after {
  transform: rotate(45deg);
}

.tylko-snackbar__close:before {
  transform: rotate(-45deg);
}

.tylko-snackbar--fade {
  opacity: 0;
}

.tylko-snackbar--material {
  position: absolute;
  bottom: 16px;
  width: auto;
  border-radius: 18px;
  border: 2px solid #cad0d0;
}

.tylko-snackbar--material .tylko-snackbar__text {
  white-space: nowrap;
}

.snackbar-top-leave-active {
  transition: all 0.3s ease-out;
}

.snackbar-top-enter-active {
  transition: all 0.3s ease-out 0.8s;
}

.snackbar-top-enter,
.snackbar-top-leave-to {
  opacity: 0;
  top: 0;
}

.snackbar-bottom-leave-active {
  transition: all 0.3s ease-out;
}

.snackbar-bottom-enter-active {
  transition: all 0.3s ease-out 0.8s;
}

.snackbar-bottom-enter,
.snackbar-bottom-leave-to {
  opacity: 0;
  bottom: 0;
}

.material-snackbar-enter-active,
.material-snackbar-leave-active {
  transition: all 0.3s ease-out;
}

.material-snackbar-enter,
.material-snackbar-leave-to {
  opacity: 0;
  bottom: 0;
}

.slider-container {
  display: flex;
  width: 100%;
  height: 36px;
}

.slider-container .noUi-target {
  width: 100%;
}

.slider-container .noUi-tooltip {
  bottom: -13px !important;
}

.slider-container .noUi-active .noUi-tooltip {
  bottom: 6px !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.slider-container.fixed-tooltip .noUi-active .noUi-tooltip {
  bottom: -13px !important;
  background-color: #fff !important;
  border-color: #09f !important;
}

.slider-controls {
  position: relative;
}

.slider-controls .minus-button,
.slider-controls .plus-button {
  position: absolute !important;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s step-end !important;
}

.slider-controls .plus-button {
  margin-left: 34px;
}

.slider-controls .minus-button {
  margin-left: -80px;
}

.slider-controls--hidden .minus-button,
.slider-controls--hidden .plus-button {
  visibility: hidden !important;
}

@media only screen and (max-width: 1023px) {
  .slider-controls .minus-button,
  .slider-controls .plus-button {
    visibility: visible;
    opacity: 1;
    transition: none !important;
  }

  .slider-controls--hidden .minus-button,
  .slider-controls--hidden .plus-button,
  .slider-controls:not(.focused) .minus-button,
  .slider-controls:not(.focused) .plus-button {
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .slider-controls:hover--hidden .minus-button,
  .slider-controls:hover--hidden .plus-button,
  .slider-mock-wrapper:hover--hidden .minus-button,
  .slider-mock-wrapper:hover--hidden .plus-button {
    visibility: hidden;
    opacity: 0;
  }

  .slider-controls:hover .minus-button,
  .slider-controls:hover .plus-button,
  .slider-mock-wrapper:hover .minus-button,
  .slider-mock-wrapper:hover .plus-button {
    visibility: visible;
    opacity: 1;
    transition: none !important;
  }
}

.thumbnails-gallery__img-wrapper {
  height: 36px;
  width: 36px;
  border: 2px solid #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  transition: width 0.4s ease, height 0.4s ease, margin-top 0.4s ease 0.2s, border-color 0.2s ease;
  outline: none;
  cursor: pointer;
}

.thumbnails-gallery__img-wrapper:hover {
  border-color: #cad0d0;
}

.thumbnails-gallery__tooltip:nth-child(2),
.thumbnails-gallery__tooltip:nth-child(3) {
  margin-top: -24px;
}

.thumbnails-gallery__tooltip:first-child {
  z-index: 3;
}

.thumbnails-gallery__tooltip:nth-child(2) {
  z-index: 2;
}

.thumbnails-gallery__tooltip:nth-child(3) {
  z-index: 1;
}

.thumbnails-gallery__img {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  transition: height 0.3s ease;
  object-fit: cover;
}

.thumbnails-gallery:hover .thumbnails-gallery__img-wrapper {
  width: 80px;
  height: 80px;
}

.thumbnails-gallery:hover .thumbnails-gallery__tooltip:nth-child(2),
.thumbnails-gallery:hover .thumbnails-gallery__tooltip:nth-child(3) {
  margin-top: 8px;
}

.thumbnails-gallery:hover .thumbnails-gallery__img {
  height: 80px;
}

.slider-mock-wrapper {
  display: flex;
  height: 36px;
  position: relative;
}

.slider-mock-wrapper:after,
.slider-mock-wrapper:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  top: 50%;
}

.slider-mock-wrapper:before {
  left: 0;
  background-color: #0d646f;
}

.slider-mock-wrapper:after {
  right: 0;
  background-color: #cad0d0;
}

.slider-controls {
  width: 100%;
}

.cta-button-wrapper {
  display: flex;
  justify-content: center;
  user-select: none;
}

.cta-button-wrapper .line-height-1 {
  line-height: 1;
}

.cta-button-wrapper .chevron-read-all {
  position: relative;
  top: -1px;
  left: 4px;
  transition: transform 0.2s ease;
}

.cta-button-wrapper .chevron-read-all .chevron-orange {
  stroke: #0d646f;
  border: #0d646f;
  fill: #0d646f;
}

.cta-button-wrapper .chevron-read-all .chevron-gray {
  stroke: #d7dadb;
  border: #d7dadb;
  fill: #d7dadb;
}

.cta-button-wrapper .chevron-read-all .chevron-svg {
  stroke-width: 0.5px;
  transform: rotate(180deg);
}

.cta-button-wrapper--hover:hover {
  cursor: pointer;
}

.cta-button-wrapper--hover:hover .chevron-read-all {
  transform: translateX(8px);
}

.tylko-link-with-cheveron--orange .tylko-link-chevron {
  stroke: #0d646f;
  border: #0d646f;
  fill: #0d646f;
}

.tylko-link-with-cheveron:hover .tylko-link-chevron {
  transform: translateX(4px) rotate(180deg);
}

.tylko-link-chevron,
.tylko-link-svg {
  display: inline;
}

.tylko-link-chevron {
  transform: rotate(180deg);
  stroke-width: 1px;
  position: relative;
  left: 0;
  transition: transform 0.2s ease;
}

.tylko-snackbar-new {
  border-radius: 12px;
  position: relative;
  white-space: nowrap;
}

.tylko-snackbar-new__close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  right: 14px;
  background-color: transparent;
  cursor: pointer;
}

.tylko-snackbar-new__close:after,
.tylko-snackbar-new__close:before {
  content: "";
  background: #7c7d81;
  position: absolute;
  width: 14px;
  height: 1px;
  left: 4.5px;
  top: 11.5px;
}

.tylko-snackbar-new__close:after {
  transform: rotate(45deg);
}

.tylko-snackbar-new__close:before {
  transform: rotate(-45deg);
}

.tylko-snackbar-new__default {
  border-radius: 50px;
}

.additional-sections-snackbar {
  position: absolute;
  transform: none;
  bottom: 16px;
  left: 0;
  top: auto;
  border-radius: 6px;
  white-space: normal;
}

@media only screen and (min-width: 1024px) {
  .additional-sections-snackbar {
    position: absolute;
    top: 16px;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translateX(-50%);
    border-radius: 12px;
    white-space: nowrap;
  }
}

.colors-wrapper {
  max-width: 100%;
  gap: 1rem;
}

.colors-wrapper--smaller-gap {
  gap: 0.5rem;
}

.colors-wrapper--chess {
  justify-content: center;
}

.colors-wrapper--limit__desktop {
  max-width: 15rem !important;
}

.colors-wrapper--limit__mobile {
  max-width: 17rem !important;
}

.multiline-button {
  width: 24px !important;
  height: 24px !important;
}

.multiline-button__mobile {
  width: 30px !important;
  height: 30px !important;
}

.wrapper-loader {
  height: 160px;
  width: 160px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}

#pdp-cplus {
  background: #fff;
  min-height: calc(100vw + 250px);
  position: relative;
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus {
    background: #fff;
    min-height: auto;
  }
}

#pdp-cplus #app {
  opacity: 0;
  transition: opacity 0.3s ease-out 1.5s;
}

#pdp-cplus #app.visible {
  opacity: 1;
}

#pdp-cplus .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-out 1.5s;
  opacity: 1;
}

#pdp-cplus .spinner.hidden {
  opacity: 0;
}

#pdp-cplus .spinner > div {
  width: 18px;
  height: 18px;
  background-color: #0d646f;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
}

#pdp-cplus .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

#pdp-cplus .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.price-wrapper-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-enter-active {
  transition: opacity 0.1s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus {
    min-height: calc(74vw - 288.98px);
  }
}

@media only screen and (min-width: 1280px) {
  #pdp-cplus {
    min-height: calc(58vw - 236.94px);
  }
}

@media only screen and (min-width: 1440px) {
  #pdp-cplus {
    min-height: calc(63vw - 301.06px);
  }
}

@media only screen and (min-width: 1680px) {
  #pdp-cplus {
    min-height: calc(56vw - 268.72px);
  }
}

@media only screen and (min-width: 1024px) {
  .configurator {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.configurator.cplus-loader {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  transition: opacity 0.1s ease;
}

.configurator.cplus-loader p {
  margin-top: -18px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .configurator.cplus-loader {
    display: block;
    z-index: 1;
    margin-top: -46px;
  }

  .configurator.cplus-loader p {
    margin-top: -11px;
  }

  .configurator.cplus-loader .skeleton-wrapper {
    margin-left: 0;
  }

  .configurator.cplus-loader .skeleton-wrapper img {
    width: 100%;
  }

  .configurator.cplus-loader .cplus-loader-container {
    margin-top: 43px;
    height: 100vw;
  }
}

.configurator .cplus-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1024px) {
  .configurator .cplus-loader-container {
    width: calc(100% - 377px);
    height: calc(74vw - 288.98px);
  }
}

@media only screen and (min-width: 1280px) {
  .configurator .cplus-loader-container {
    width: calc(100% - 393px);
    height: calc(58vw - 236.94px);
  }
}

@media only screen and (min-width: 1440px) {
  .configurator .cplus-loader-container {
    width: calc(100% - 462px);
    height: calc(63vw - 301.06px);
  }
}

@media only screen and (min-width: 1680px) {
  .configurator .cplus-loader-container {
    width: calc(100% - 462px);
    height: calc(56vw - 268.72px);
  }
}

.configurator .skeleton-wrapper {
  margin-left: -32px;
}

@media only screen and (min-width: 1024px) {
  .configurator .skeleton-wrapper {
    width: 393px;
  }
}

@media only screen and (min-width: 1440px) {
  .configurator .skeleton-wrapper {
    width: 420px;
  }
}

@media only screen and (min-width: 1024px) {
  .configurator {
    position: relative;
  }
}

.configurator .configurator-wrapper {
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .configurator .configurator-wrapper {
    margin-top: 0;
    width: calc(100% - 377px);
  }
}

@media only screen and (min-width: 1280px) {
  .configurator .configurator-wrapper {
    width: calc(100% - 393px);
  }
}

@media only screen and (min-width: 1440px) {
  .configurator .configurator-wrapper {
    width: calc(100% - 462px);
  }
}

@media only screen and (min-width: 1680px) {
  .configurator .configurator-wrapper {
    width: calc(100% - 462px);
  }
}

.configurator .configurator-wrapper canvas {
  display: block;
  width: 100%;
  max-width: 100%;
}

.configurator .configurator-wrapper .interaction-layer {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1023px) {
  .configurator .configurator-wrapper .mask {
    pointer-events: none;
    position: absolute;
    display: block;
    z-index: 1;
  }

  .configurator .configurator-wrapper .mask.mask-right {
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;
    background: linear-gradient(270deg, #fff 0, hsla(0, 0%, 94.1%, 0));
  }

  .configurator .configurator-wrapper .mask--bottom {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, #fff 0, hsla(0, 0%, 94.1%, 0));
  }
}

.configurator .desktop-controls-wrapper {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1024px) {
  .configurator .desktop-controls-wrapper {
    width: 393px;
    position: relative;
    z-index: 3;
  }
}

@media only screen and (min-width: 1280px) {
  .configurator .desktop-controls-wrapper {
    width: 393px;
  }
}

@media only screen and (min-width: 1440px) {
  .configurator .desktop-controls-wrapper {
    width: 420px;
  }
}

@media only screen and (min-width: 1680px) {
  .configurator .desktop-controls-wrapper {
    width: 420px;
  }
}

.configurator .desktop-controls-wrapper .tooltip-wrapper {
  position: absolute;
  right: calc(100% + 8px);
  top: 67px !important;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1920px) {
  .configurator .desktop-controls-wrapper .tooltip-wrapper {
    top: 77px !important;
  }
}

.configurator .desktop-controls-wrapper .tooltip-wrapper--no-outline {
  outline: none;
}

.configurator .configurator-modal canvas {
  max-width: 100%;
  max-height: 100vw;
}

.configurator .cplus-divider {
  width: 100%;
  border-top: 1px solid #edf0f0;
}

.configurator .pdp-2018-tooltip-wrapper .pdp-2018-tooltip {
  margin-left: -80px;
  border: 2px solid #cad0d0;
}

.configurator .pdp-2018-tooltip-wrapper .pdp-2018-tooltip--right {
  margin-left: -130px;
}

.configurator .pdp-2018-tooltip-wrapper .pdp-2018-tooltip.depth {
  margin-left: 0;
}

.configurator .pdp-2018-tooltip-wrapper .pdp-2018-tooltip--visible {
  display: block;
}

.c-button,
.toggle-group .overlay,
.toggle-group .toggle-button {
  display: block;
  text-decoration: none;
  padding: 8px 16px;
  color: #484444;
  border: 2px solid #edf0f0;
  border-radius: 30px;
  cursor: pointer;
  transition: color 0.2s ease;
  outline: none;
}

.c-button.active,
.c-button:hover,
.toggle-group .active.overlay,
.toggle-group .active.toggle-button,
.toggle-group .overlay:hover,
.toggle-group .toggle-button:hover {
  color: #0d646f;
}

.c-tab-button {
  padding: 8px 16px;
  color: #484444;
  background-color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  outline: none;
}

.c-tab-button:hover {
  color: #0d646f;
}

.c-tab-button.active,
.c-tab-button:active {
  color: #0d646f;
  background-color: #ffebe5;
}

.c-tab-button.active:hover {
  background-color: #ffebe5;
}

.c-button-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  background-size: cover;
  border: 2px solid #edf0f0;
  overflow: hidden;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  outline: none;
  cursor: pointer;
}

.c-button-round img {
  width: 100%;
}

.c-button-round.c-button-round--with-arrow,
.c-button-round.c-button-round--with-arrow.control-btn:hover {
  border-color: #fff;
}

.c-button-round.control-btn {
  width: 36px;
  height: 36px;
}

.c-button-round.control-btn svg path {
  transition: fill 0.2s ease;
}

.c-button-round.control-btn:active {
  border-color: #7c7d81 !important;
}

.c-button-round.control-btn.active {
  border-color: #7c7d81;
}

.c-button-round.control-btn.active:hover,
.c-button-round.control-btn.forcedHover,
.c-button-round.control-btn:hover {
  border-color: #cad0d0;
}

@media only screen and (min-width: 1024px) {
  .c-button-round.states:hover {
    border-color: #edf0f0;
  }

  .c-button-round.states:hover svg path {
    fill: #0d646f;
  }
}

.c-button-round.states.active,
.c-button-round.states:active {
  border-color: #fbc3b2;
}

.c-button-round.states.active svg path,
.c-button-round.states:active svg path {
  fill: #0d646f;
}

.c-button-round.states.active:hover {
  background-color: #ffebe5;
}

.c-button-round.states.disabled:hover svg path,
.c-button-round.states.disabled svg path {
  fill: #cad0d0 !important;
}

@media only screen and (min-width: 1024px) {
  .c-button-round:hover {
    border-color: #0d646f;
  }
}

.c-button-round.disabled svg path {
  fill: #cad0d0;
}

.c-button-round.disabled:hover {
  border-color: #edf0f0;
}

.c-button-round.active,
.c-button-round:active {
  border-color: #0d646f;
}

.c-button-round.hovered svg path {
  fill: #0d646f;
}

.cta-button {
  color: #7c7d81;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  height: 48px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.cta-button .button-wrapper,
.cta-button .cart-icon,
.cta-button .loader-wrapper {
  transition: opacity 0.2s ease;
}

.cta-button .button-wrapper,
.cta-button .cart-icon {
  opacity: 1;
}

.cta-button.loading .button-wrapper,
.cta-button.loading .cart-icon {
  opacity: 0;
}

.cta-button.loading .loader-wrapper {
  opacity: 1;
}

.cta-button .loader-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.cta-button .loader-wrapper .spinner {
  margin-top: 4px;
  transform: scale(0.5) !important;
  position: static !important;
  left: unset !important;
  top: unset !important;
}

.cta-button.orange-button {
  color: #fff;
  background-color: #0d646f;
}

.cta-button.orange-button.loading {
  background-color: #09f;
}

@media only screen and (min-width: 1024px) {
  .cta-button.orange-button:hover {
    background-color: #09f;
  }
}

.cta-button.orange-button:focus {
  background-color: #0d646f;
}

.cta-button.orange-button .loader-wrapper .spinner div {
  background-color: #fff !important;
}

.cta-button.orange-button svg path {
  fill: #fff;
}

.cta-button.white-button {
  border: 2px solid #edf0f0 !important;
  background-color: #fff !important;
}

@media only screen and (min-width: 1024px) {
  .cta-button.white-button:hover {
    color: #0d646f !important;
  }

  .cta-button.white-button:hover svg path {
    fill: #0d646f !important;
  }
}

.cta-button.white-button:active {
  color: #0d646f;
}

.cta-button.white-button:active svg path {
  fill: #0d646f;
}

.cta-button.add-to-cart-button svg {
  width: 20.4px;
  height: 20.4px;
}

.cta-button.disabled {
  background: #edf0f0;
  color: #d7dadb;
  pointer-events: none;
}

.cta-button.disabled svg path {
  fill: #d7dadb;
}

.c-button-thumbnail {
  border-radius: 6px;
  border: 2px solid #cad0d0;
  background-color: #fff;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  position: relative;
  outline: none;
  cursor: pointer;
}

.c-button-thumbnail img {
  max-width: 100%;
  height: auto;
  display: block;
}

.c-button-thumbnail .loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.c-button-thumbnail.active {
  border-color: #0d646f;
}

.c-button-thumbnail.active:hover {
  background-color: #ffebe5;
  border-color: #09f;
}

@media only screen and (min-width: 1024px) {
  .c-button-thumbnail:hover {
    border-color: #09f;
  }
}

.c-button-thumbnail:active {
  border-color: #09f;
}

.c-button-thumbnail:disabled {
  background-color: #edf0f0;
  border-color: #edf0f0;
  color: #cad0d0;
}

.c-button-thumbnail:disabled svg {
  opacity: 0.8;
}

.c-button-thumbnail:disabled:hover {
  background-color: #edf0f0;
  border-color: #edf0f0;
  color: #cad0d0;
}

.c-button-thumbnail:disabled.active,
.c-button-thumbnail:disabled.active:hover {
  background-color: #fff;
  border-color: #edf0f0;
  color: #09f;
}

.material-button {
  width: 36px;
  height: 36px;
  border-color: #edf0f0;
}

.material-button.active,
.material-button.active:hover {
  border-color: #0d646f;
}

.material-button:hover {
  border-color: #09f;
}

html:not([dir="rtl"]) #pdp-cplus .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

html:not([dir="rtl"]) #pdp-cplus .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

#pdp-cplus .noUi-target,
#pdp-cplus .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#pdp-cplus .noUi-target {
  position: relative;
  direction: ltr;
}

#pdp-cplus .noUi-base,
#pdp-cplus .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

#pdp-cplus .noUi-connects {
  overflow: hidden;
}

#pdp-cplus .noUi-connect,
#pdp-cplus .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

#pdp-cplus .noUi-vertical .noUi-origin {
  width: 0;
}

#pdp-cplus .noUi-horizontal .noUi-origin {
  height: 0;
}

#pdp-cplus .noUi-handle {
  position: absolute;
}

#pdp-cplus .noUi-state-tap .noUi-connect {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

#pdp-cplus .noUi-state-drag * {
  cursor: inherit !important;
}

#pdp-cplus .noUi-vertical {
  width: 18px;
}

#pdp-cplus .noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

#pdp-cplus .noUi-target {
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0 1px 1px #fff, 0 3px 6px -5px #bbb;
}

#pdp-cplus .noUi-connects {
  border-radius: 3px;
}

#pdp-cplus .noUi-connect {
  background: #3fb8af;
}

#pdp-cplus .noUi-draggable {
  cursor: ew-resize;
}

#pdp-cplus .noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

#pdp-cplus .noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

#pdp-cplus .noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

#pdp-cplus .noUi-handle:after,
#pdp-cplus .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px;
}

#pdp-cplus .noUi-handle:after {
  left: 17px;
}

#pdp-cplus .noUi-vertical .noUi-handle:after,
#pdp-cplus .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

#pdp-cplus .noUi-vertical .noUi-handle:after {
  top: 17px;
}

#pdp-cplus [disabled] .noUi-connect {
  background: #b8b8b8;
}

#pdp-cplus [disabled].noUi-handle,
#pdp-cplus [disabled] .noUi-handle,
#pdp-cplus [disabled].noUi-target {
  cursor: not-allowed;
}

#pdp-cplus .noUi-pips,
#pdp-cplus .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#pdp-cplus .noUi-pips {
  position: absolute;
  color: #999;
}

#pdp-cplus .noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

#pdp-cplus .noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

#pdp-cplus .noUi-marker {
  position: absolute;
  background: #ccc;
}

#pdp-cplus .noUi-marker-large,
#pdp-cplus .noUi-marker-sub {
  background: #aaa;
}

#pdp-cplus .noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

#pdp-cplus .noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

#pdp-cplus .noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

#pdp-cplus .noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

#pdp-cplus .noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

#pdp-cplus .noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

#pdp-cplus .noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

#pdp-cplus .noUi-value-vertical {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 25px;
}

#pdp-cplus .noUi-rtl .noUi-value-vertical {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

#pdp-cplus .noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

#pdp-cplus .noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

#pdp-cplus .noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

#pdp-cplus .noUi-tooltip {
  display: flex;
  justify-content: center;
  position: absolute;
  background: #fff;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  padding: 8px;
  cursor: pointer;
}

#pdp-cplus .noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  left: 50%;
  bottom: 120%;
}

#pdp-cplus .noUi-vertical .noUi-tooltip {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 120%;
}

#pdp-cplus .noUi-connect {
  background-color: #0d646f;
}

#pdp-cplus .step-slider.noUi-horizontal .noUi-handle {
  background-color: transparent;
  border-color: transparent;
}

#pdp-cplus .noUi-horizontal {
  height: 2px;
}

#pdp-cplus .noUi-horizontal .noUi-handle {
  top: -5.5px;
  right: -1px !important;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  border: 2px solid #0d646f;
  background-color: #fff;
  box-shadow: none;
}

#pdp-cplus .noUi-horizontal .noUi-handle:after,
#pdp-cplus .noUi-horizontal .noUi-handle:before {
  display: none;
}

#pdp-cplus .noUi-horizontal .noUi-handle:focus {
  outline: none;
}

#pdp-cplus .noUi-horizontal .noUi-handle.noUi-active .noUi-tooltip {
  bottom: 25px;
}

#pdp-cplus .noUi-horizontal .noUi-handle .noUi-tooltip {
  bottom: -6px;
}

#pdp-cplus .activeExtension .noUi-horizontal .noUi-handle.noUi-active .noUi-tooltip {
  bottom: -6px !important;
}

#pdp-cplus .noUi-tooltip {
  width: 75px;
  color: #0d646f;
  border: 2px solid #cad0d0;
  border-radius: 30px;
}

#pdp-cplus .noUi-marker {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  margin-top: -15px;
  margin-left: -7px !important;
}

#pdp-cplus .noUi-marker.noUi-marker-sub {
  background: #0d646f;
}

#pdp-cplus .noUi-marker.noUi-marker-large,
#pdp-cplus .noUi-target {
  background-color: #cad0d0;
}

#pdp-cplus .noUi-target {
  border: none;
  box-shadow: none;
  border-radius: 15px;
  transform: translateY(18px);
  z-index: 10;
}

#pdp-cplus .slider-wrapper {
  width: 100%;
  height: 36px;
}

#pdp-cplus .noUi-value,
#pdp-cplus .slider-wrapper:not(.active) {
  display: none;
}

#pdp-cplus .sliders-container {
  width: 100%;
  height: 36px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .sliders-container:not(.stepSlider):before {
    content: "";
    position: absolute;
    height: 2px;
    width: 6px;
    background-color: #0d646f;
    top: 50%;
    left: -5px;
  }
}

#pdp-cplus .sliders-container .extension {
  max-width: none !important;
  display: none;
  position: absolute;
  white-space: nowrap;
}

#pdp-cplus .sliders-container .narrower {
  margin-left: 18px !important;
  margin-top: -7px !important;
  padding-left: 30px !important;
}

#pdp-cplus .sliders-container .wider {
  margin-left: 0 !important;
  right: 17px;
  margin-top: -8px !important;
  padding-right: 30px !important;
}

#pdp-cplus .sliders-container .slider-wrapper .extension.wider {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#pdp-cplus .sliders-container .slider-wrapper .extension.narrower {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#pdp-cplus .sliders-container .slider-wrapper .extension.narrower,
#pdp-cplus .sliders-container .slider-wrapper .extension.wider {
  transition: color 0.2s ease-in-out;
}

#pdp-cplus .sliders-container .slider-wrapper .extension.narrower:hover,
#pdp-cplus .sliders-container .slider-wrapper .extension.wider:hover {
  color: #0d646f;
}

#pdp-cplus .sliders-container .slider-wrapper.t-sm.narrowerVisible .noUi-active .noUi-tooltip,
#pdp-cplus .sliders-container .slider-wrapper.t-sm.widerVisible .noUi-active .noUi-tooltip {
  bottom: -13px !important;
  background-color: #fff;
  border-color: #09f;
}

#pdp-cplus .sliders-container .slider-wrapper.t-sm .noUi-active .noUi-tooltip {
  bottom: 7px !important;
  background-color: transparent;
  border-color: transparent;
}

#pdp-cplus .sliders-container .slider-wrapper.t-sm .noUi-tooltip {
  bottom: -13px;
}

#pdp-cplus .sliders-container .slider-wrapper.t-sm .extension {
  margin-top: -15px !important;
}

#pdp-cplus .narrowerVisible .narrower,
#pdp-cplus .widerVisible .wider {
  display: block;
}

#pdp-cplus .noUi-pips-horizontal {
  height: 0;
}

#pdp-cplus .noUi-base .noUi-active .noUi-tooltip {
  color: #0d646f;
}

@media only screen and (min-width: 1024px) {
  #pdp-cplus .noUi-base .noUi-tooltip:hover {
    color: #0d646f;
    border-color: #09f;
  }
}

@media only screen and (max-width: 1023px) {
  #pdp-cplus .noUi-base .noUi-tooltip:active {
    color: #0d646f;
    border-color: #09f;
  }
}

.toggle-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #edf0f0;
  background-color: #edf0f0;
  border-radius: 30px;
  transition: border-color 0.2s ease;
}

.toggle-group .overlay {
  position: absolute;
  background-color: #fff;
  transition: left 0.2s ease, width 0.3s ease;
  color: #0d646f;
  border-color: #cad0d0;
}

.toggle-group .toggle-button {
  border: none;
  background-color: transparent;
}

.toggle-group .toggle-button.active.disabled {
  color: #0d646f;
}

.toggle-group .toggle-button.disabled {
  color: #cad0d0;
  cursor: default;
}

.toggle-group.display-svg .toggle-button svg path {
  transition: fill 0.2s ease;
}

.toggle-group.display-svg .toggle-button.disabled svg path {
  fill: #cad0d0;
}

.toggle-group.display-svg .toggle-button:hover svg path {
  fill: #0d646f;
}

.toggle-group.display-svg .overlay,
.toggle-group.display-svg .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.toggle-group.display-svg .overlay svg path {
  fill: #0d646f;
}

.toggle-group.display-svg .overlay-svg-wrapper,
.toggle-group.display-svg .svg-wrapper-button {
  display: inline-flex;
  padding-right: 2px;
}

.toggle-group.display-svg .button-label {
  display: inline-flex;
}

.button-under-labels .overlay {
  color: #fff;
}

.button-under-labels .overlay svg {
  opacity: 0;
}

.button-under-labels .toggle-button {
  z-index: 1;
}

.button-under-labels .active.toggle-button .svg-wrapper-button svg path {
  fill: #0d646f;
}

.toggle-group-fixed-height-wrapper {
  min-height: 37px;
}

.cplus-toggle-checkbox {
  width: 72px;
  height: 36px;
  background-color: #f5f5f5;
  border: 1px solid #edf0f0;
  border-radius: 30px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.cplus-toggle-checkbox.disabled {
  opacity: 1 !important;
}

.cplus-toggle-checkbox.disabled.active {
  background-color: #ffd8cd;
  border-color: #fbc3b2;
}

.cplus-toggle-checkbox span {
  left: 0;
  top: -1px;
  position: absolute;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: 2px solid #cad0d0;
  border-radius: 50%;
  transition: left 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.cplus-toggle-checkbox.active span {
  left: calc(100% - 36px);
  border-color: #0d646f;
}

.configurator-wrapper .minus-button,
.configurator-wrapper .plus-button,
.cplus-stepper .minus-button,
.cplus-stepper .plus-button,
.slider-controls .minus-button,
.slider-controls .plus-button {
  cursor: pointer;
  position: relative;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #cad0d0;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

@media only screen and (min-width: 1024px) {
  .configurator-wrapper .minus-button.extended,
  .configurator-wrapper .minus-button:hover,
  .configurator-wrapper .plus-button.extended,
  .configurator-wrapper .plus-button:hover,
  .cplus-stepper .minus-button.extended,
  .cplus-stepper .minus-button:hover,
  .cplus-stepper .plus-button.extended,
  .cplus-stepper .plus-button:hover,
  .slider-controls .minus-button.extended,
  .slider-controls .minus-button:hover,
  .slider-controls .plus-button.extended,
  .slider-controls .plus-button:hover {
    border-color: #09f;
  }

  .configurator-wrapper .minus-button.extended:after,
  .configurator-wrapper .minus-button.extended:before,
  .configurator-wrapper .minus-button:hover:after,
  .configurator-wrapper .minus-button:hover:before,
  .configurator-wrapper .plus-button.extended:after,
  .configurator-wrapper .plus-button.extended:before,
  .configurator-wrapper .plus-button:hover:after,
  .configurator-wrapper .plus-button:hover:before,
  .cplus-stepper .minus-button.extended:after,
  .cplus-stepper .minus-button.extended:before,
  .cplus-stepper .minus-button:hover:after,
  .cplus-stepper .minus-button:hover:before,
  .cplus-stepper .plus-button.extended:after,
  .cplus-stepper .plus-button.extended:before,
  .cplus-stepper .plus-button:hover:after,
  .cplus-stepper .plus-button:hover:before,
  .slider-controls .minus-button.extended:after,
  .slider-controls .minus-button.extended:before,
  .slider-controls .minus-button:hover:after,
  .slider-controls .minus-button:hover:before,
  .slider-controls .plus-button.extended:after,
  .slider-controls .plus-button.extended:before,
  .slider-controls .plus-button:hover:after,
  .slider-controls .plus-button:hover:before {
    background-color: #0d646f;
  }

  .configurator-wrapper .minus-button.extended svg path,
  .configurator-wrapper .minus-button:hover svg path,
  .configurator-wrapper .plus-button.extended svg path,
  .configurator-wrapper .plus-button:hover svg path,
  .cplus-stepper .minus-button.extended svg path,
  .cplus-stepper .minus-button:hover svg path,
  .cplus-stepper .plus-button.extended svg path,
  .cplus-stepper .plus-button:hover svg path,
  .slider-controls .minus-button.extended svg path,
  .slider-controls .minus-button:hover svg path,
  .slider-controls .plus-button.extended svg path,
  .slider-controls .plus-button:hover svg path {
    fill: #0d646f;
  }
}

.configurator-wrapper .minus-button:active,
.configurator-wrapper .plus-button:active,
.cplus-stepper .minus-button:active,
.cplus-stepper .plus-button:active,
.slider-controls .minus-button:active,
.slider-controls .plus-button:active {
  border-color: #0d646f;
}

.configurator-wrapper .minus-button:active:after,
.configurator-wrapper .minus-button:active:before,
.configurator-wrapper .plus-button:active:after,
.configurator-wrapper .plus-button:active:before,
.cplus-stepper .minus-button:active:after,
.cplus-stepper .minus-button:active:before,
.cplus-stepper .plus-button:active:after,
.cplus-stepper .plus-button:active:before,
.slider-controls .minus-button:active:after,
.slider-controls .minus-button:active:before,
.slider-controls .plus-button:active:after,
.slider-controls .plus-button:active:before {
  background-color: #0d646f;
}

.configurator-wrapper .minus-button:active svg path,
.configurator-wrapper .plus-button:active svg path,
.cplus-stepper .minus-button:active svg path,
.cplus-stepper .plus-button:active svg path,
.slider-controls .minus-button:active svg path,
.slider-controls .plus-button:active svg path {
  fill: #0d646f;
}

.configurator-wrapper .minus-button.disabled,
.configurator-wrapper .plus-button.disabled,
.cplus-stepper .minus-button.disabled,
.cplus-stepper .plus-button.disabled,
.slider-controls .minus-button.disabled,
.slider-controls .plus-button.disabled {
  background-color: transparent;
  border-color: #edf0f0;
  cursor: default;
}

.configurator-wrapper .minus-button.disabled:after,
.configurator-wrapper .minus-button.disabled:before,
.configurator-wrapper .plus-button.disabled:after,
.configurator-wrapper .plus-button.disabled:before,
.cplus-stepper .minus-button.disabled:after,
.cplus-stepper .minus-button.disabled:before,
.cplus-stepper .plus-button.disabled:after,
.cplus-stepper .plus-button.disabled:before,
.slider-controls .minus-button.disabled:after,
.slider-controls .minus-button.disabled:before,
.slider-controls .plus-button.disabled:after,
.slider-controls .plus-button.disabled:before {
  background-color: #cad0d0 !important;
}

.configurator-wrapper .minus-button.disabled svg path,
.configurator-wrapper .plus-button.disabled svg path,
.cplus-stepper .minus-button.disabled svg path,
.cplus-stepper .plus-button.disabled svg path,
.slider-controls .minus-button.disabled svg path,
.slider-controls .plus-button.disabled svg path {
  fill: #cad0d0 !important;
}

.configurator-wrapper .minus-button:before,
.configurator-wrapper .plus-button:before,
.cplus-stepper .minus-button:before,
.cplus-stepper .plus-button:before,
.slider-controls .minus-button:before,
.slider-controls .plus-button:before {
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  content: "";
  background-color: #484444;
  width: 14px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.configurator-wrapper .minus-button svg path,
.configurator-wrapper .plus-button svg path,
.cplus-stepper .minus-button svg path,
.cplus-stepper .plus-button svg path,
.slider-controls .minus-button svg path,
.slider-controls .plus-button svg path {
  transition: fill 0.2s ease-in-out;
  fill: #484444;
}

.configurator-wrapper .plus-button:after,
.cplus-stepper .plus-button:after,
.slider-controls .plus-button:after {
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  content: "";
  background-color: #484444;
  width: 2px;
  height: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.configurator-wrapper.custom-images button:after,
.configurator-wrapper.custom-images button:before,
.cplus-stepper.custom-images button:after,
.cplus-stepper.custom-images button:before,
.slider-controls.custom-images button:after,
.slider-controls.custom-images button:before {
  display: none;
}

.configurator-wrapper.custom-images button:last-of-type svg,
.cplus-stepper.custom-images button:last-of-type svg,
.slider-controls.custom-images button:last-of-type svg {
  transform: rotate(180deg);
}

.cplus-stepper {
  border-radius: 30px;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
}

.cplus-stepper .step {
  text-align: center;
  width: 50px;
}

.le-helper-wrapper {
  transition: opacity 0.2s ease;
}

.le-helper-wrapper.hidden {
  opacity: 0;
}

.le-helper {
  position: absolute;
  width: 2px;
  transform-origin: 0 0 0;
  border-left: 2px dashed #7c7d81;
}

.le-slider-wrapper {
  position: absolute;
  transition: opacity 0.2s ease;
  opacity: 1;
}

.le-slider-wrapper.hidden {
  opacity: 0;
}

.le-slider-wrapper .noUi-target {
  transform: translateY(0) !important;
  height: 0;
  border: none;
}

.le-slider-wrapper .noUi-base {
  height: 0.0001px;
}

.le-slider-wrapper .noUi-horizontal {
  height: 0 !important;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle {
  border-radius: 0;
  width: 35px;
  height: 45px;
  transform: translateX(1px);
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle:after {
  opacity: 0;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle.noUi-active .mock:before {
  border-color: #0d646f;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle.noUi-active .mock:after {
  border-color: #0d646f !important;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle:hover .mock:before {
  border-color: #0d646f;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle .mock {
  position: absolute;
  margin-left: 3px;
  content: "";
  width: 1px;
  height: 128px;
  background-color: #7c7d81;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle .mock:before {
  width: 16px;
  height: 40px;
  border: 2px solid #7c7d81;
  border-radius: 12px;
  background-color: #fff;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle .mock:after,
.le-slider-wrapper .noUi-horizontal .noUi-handle .mock:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transition: border-color 0.2s ease;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-sizing: content-box;
}

.le-slider-wrapper .noUi-horizontal .noUi-handle .mock:after {
  width: 0;
  height: 16px;
  border: 1px solid #7c7d81;
  border-radius: 4px;
}

#pdp-cplus .configurator-header {
  position: fixed;
  z-index: 2003;
  top: 0;
  height: 43px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#pdp-cplus .configurator-header .price {
  color: #000;
}

#pdp-cplus .configurator-modal {
  position: fixed;
  overflow-y: scroll;
  box-sizing: border-box;
  max-height: 100%;
  background-color: #fff;
  width: 100%;
  z-index: 2002;
  padding-top: 43px;
  top: 0;
}

#pdp-cplus .active-component-wrapper {
  padding: 16px 0 0 !important;
  background-color: #fff;
  border-radius: 0;
  position: relative;
}

#pdp-cplus .active-component-wrapper:before {
  height: 0;
  width: 32px;
  border: 1px solid #cad0d0;
  border-radius: 1.5px;
  content: "";
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

#pdp-cplus .canvas-controls,
#pdp-cplus .controls-wrapper {
  position: relative;
}

#pdp-cplus .canvas-controls .control-btn {
  position: absolute;
  top: 8px;
  z-index: 1;
}

#pdp-cplus .canvas-controls .control-btn.prev-component {
  left: 16px;
}

#pdp-cplus .canvas-controls .control-btn.next-component {
  right: 16px;
}

#pdp-cplus .back-button {
  background-color: #fff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pdp-cplus .configurator-articles .article-title {
  color: #000;
}

#pdp-cplus .configurator-articles .article-image {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

#pdp-cplus .configurator-articles .article-text {
  color: #484444;
}

#pdp-cplus .thumbs .thumbs-wrapper {
  box-sizing: content-box;
}

#pdp-cplus .thumbs .thumbs-wrapper:first-child {
  padding-left: 16px !important;
}

#pdp-cplus .thumbs .thumbs-wrapper:last-child {
  padding-right: 16px;
}

#pdp-cplus .thumbs .thumbs-wrapper .thumbnail-icon--wardrobe {
  width: 34px;
  height: 91px;
}

#pdp-cplus .thumbs .container,
#pdp-cplus .thumbs .mini,
#pdp-cplus .thumbs img {
  width: 68px;
  height: 68px;
}

#pdp-cplus .desktop-active-component {
  border: 2px solid #cad0d0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 4;
  transform: translateX(-50%);
  margin-top: 29px;
  padding: 16px 0 !important;
}

#pdp-cplus .desktop-active-component .doors-direction {
  max-width: 384px;
}

#pdp-cplus .desktop-active-component .desktop-cell {
  justify-content: flex-start;
}

#pdp-cplus .tooltip-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

#pdp-cplus .tooltip-container .tooltip-item {
  opacity: 0;
  display: none;
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  z-index: -1;
  margin-left: 30px;
  min-width: 0;
  transition: left 0.4s ease, opacity 0.2s ease;
}

#pdp-cplus .tooltip-container .tooltip-item.show {
  left: -30px;
  z-index: 1000;
}

#pdp-cplus .tooltip-container .tooltip-item .label-wrapper {
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  border-radius: 30px;
  background-color: #fff;
  border: 2px solid #cad0d0;
  white-space: nowrap;
}

#pdp-cplus .tooltip-container .t-button {
  padding: 3px;
}

.configurator-tooltip {
  box-sizing: border-box;
  cursor: pointer;
  height: 20px;
  position: relative;
}

.configurator-tooltip svg path {
  transition: fill 0.2s ease;
}

.configurator-tooltip:hover svg path {
  fill: #0d646f;
}

.configurator-tooltip__corridor {
  width: 36px;
  bottom: -10px;
  height: 44px;
}

.configurator-tooltip__box,
.configurator-tooltip__corridor {
  cursor: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.configurator-tooltip__box {
  width: 270px;
  border: 2px solid #cad0d0;
  border-radius: 12px;
  box-sizing: border-box;
  top: 30px;
  z-index: 10;
}

.configurator-tooltip--header {
  position: absolute;
  height: 100%;
  width: 100%;
}

.configurator-tooltip--header .configurator-tooltip__box {
  top: auto;
  bottom: 90px;
}

.mobile-indicator-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  touch-action: none;
  pointer-events: none;
}

.mobile-indicator-wrapper .relative {
  position: relative;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  margin-left: -13px;
}

.mobile-indicator-wrapper .pulsar {
  border: 0;
  border-radius: 50%;
  display: block;
  background-color: #edf0f0;
  opacity: 0.85;
}

.mobile-indicator-wrapper .pulsar,
.mobile-indicator-wrapper .pulsar__img {
  width: 36px;
  height: 36px;
  position: relative;
}

.mobile-indicator-wrapper .pulsar:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid #0d646f;
  animation: borderPulse 1.5s ease-out infinite;
}

@keyframes borderPulse {
  0% {
    width: 18px;
    height: 18px;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  to {
    width: 44px;
    height: 44px;
    opacity: 0;
  }
}

button:focus {
  outline: none !important;
}

.menu {
  background: #fff;
  float: left;
  width: 420px;
  height: auto;
  padding: 20px;
}

.menu-wrapper button {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #edf0f0;
  border-color: #edf0f0;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.menu-wrapper .mt-4 {
  margin-top: 4px !important;
}

.menu-switcher {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 8px;
}

.c-button-thumbnail {
  border-radius: 6px;
  border: 2px solid #cad0d0;
  background-color: #fff;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  position: relative;
  outline: none;
  cursor: pointer;
}

.c-button-thumbnail.active {
  border-color: #0d646f;
}

.menu-wrapper button button.active {
  background-color: #fff;
  border-color: #cad0d0;
}

.menu-wrapper__text {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0;
}

.menu-wrapper button.active {
  color: #0d646f;
}

.menu-wrapper button:hover {
  color: #0d646f;
}

.menu-wrapper button.active path {
  fill: #0d646f;
}

.menu-wrapper button:hover path {
  fill: #0d646f;
}

.menu-title {
  display: table;
}

.menu-title p {
  display: table-cell;
  vertical-align: middle;
}

.menu-title .bold-14,
.menu-title .normal-14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0;
}

.menu-title .text-offblack-800 {
  color: #1f1d1d;
}

.mb-20 {
  margin-bottom: 20px;
}

#container {
  float: left;
  width: 200px;
  height: 40px;
  margin: auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  border: 2px solid #edf0f0;
  background-color: #edf0f0;
  border-radius: 30px;
  transition: border-color 0.2s ease;
}

.inner-container {
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  font-size: 0.6em;
  letter-spacing: 0.2em;
}

.inner-container:first-child {
  background: #e9e9e9;
  color: #a9a9a9;
}

.inner-container:nth-child(2) {
  background: dodgerblue;
  color: white;
  clip-path: inset(0 50% 0 0);
  transition: 0.3s cubic-bezier(0, 0, 0, 1);
}

.toggle {
  width: 50%;
  position: absolute;
  height: inherit;
  display: flex;
  box-sizing: border-box;
  border-radius: 30px;
}

.toggle p {
  margin: auto;
}

.toggle:nth-child(1) {
  right: 0;
}

.visible-false {
  visibility: hidden;
}

/* CUSTOM RANGE INPUT */
.range-div {
  position: relative;
  width: 100%;
}

.range {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(to right, #cad0d0, #cad0d0);
  width: 100%;
  height: 1px;
  outline: none;
  margin-bottom: 20px;
}

.range:active::-webkit-slider-thumb {
  border: 2px solid #0d646f;
}

.range::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 37px;
  width: 100px;
  background: #fff;
  border-radius: 30px;
  cursor: grab;
  border: 2px solid #cad0d0;
}

.range-thumb-text {
  position: absolute;
  /* half :thumb width */
  top: 1px;
  width: 100px;
  /* same as :thumb */
  height: 30px;
  /* same as :thumb */
  text-align: center;
  color: #0d646f;
  line-height: 30px;
  font-size: 12px;
  pointer-events: none;
  /* ignore mouse */
  font-weight: bold;
}

.range-thumb-text::before {
  content: attr(data-val) " ";
}

.minus {
  position: absolute;
  right: 102%;
  /* half :thumb width */
  top: -1px;
  width: 100px;
  /* same as :thumb */
  height: 30px;
  /* same as :thumb */
  text-align: center;
  color: #0d646f;
  line-height: 30px;
  font-size: 12px;
  /* ignore mouse */
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 36px;
  cursor: grab;
  border: 2px solid #cad0d0;
}

.plus {
  position: absolute;
  /* left: 102%; */
  /* half :thumb width */
  top: -1px;
  width: 100px;
  /* same as :thumb */
  height: 30px;
  /* same as :thumb */
  text-align: center;
  color: #0d646f;
  line-height: 30px;
  font-size: 12px;
  /* ignore mouse */
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 36px;
  cursor: grab;
  border: 2px solid #cad0d0;
}

.categoriesButton-3 {
  background-color: #fff;
  color: #0d646f;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoriesButton-3 svg {
  transform: rotate(180deg) !important;
  fill: #0d646f;
}

.yanyana {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}

.rotateDoor {
  transform: rotateY(180deg);
}

/* .trackContainer {
  width: 1px;
  height: 128px;
  background-color: #7c7d81;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track {
  width: 16px;
  height: 40px;
  border: 2px solid #7c7d81;
  border-radius: 12px;
  background-color: #fff;
} */

.mock {
  position: absolute;
  margin-left: -7px;
  content: "";
  width: 1px;
  height: 128px;
  background-color: #7c7d81;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.mock:before {
  width: 16px;
  height: 40px;
  border: 2px solid #7c7d81;
  border-radius: 12px;
  background-color: #fff;
}

.mock:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transition: border-color 0.2s ease;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-sizing: content-box;
}

.mock:after {
  width: 0;
  height: 16px;
  border: 1px solid #7c7d81;
  border-radius: 4px;
}

.mock:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transition: border-color 0.2s ease;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-sizing: content-box;
}

.comment {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.comment textarea {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 13px;
  margin: 10px 0 26px 0;
  width: 100%;
  border-radius: 3px;
  resize: none;
}

.comment button {
  width: 50%;
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 40%;
  height: 30px;
  text-transform: capitalize !important;
}

.comment p {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0;
}

.comment small {
  font-size: 12px;
  color: #7c7d81;
}

.addCardContainer {
  display: flex;
  width: 52%;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  border: 2px solid #cad0d0;
  border-radius: 100px;
  position: relative;
  height: 50px;
}

.addCardContainer p {
  width: 33%;
  text-align: center;
}

.addCard {
  background-color: #cad0d0;
  line-height: 50px;
  width: 33%;
  text-align: center;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  cursor: pointer;
}

.removeCard {
  background-color: #cad0d0;
  line-height: 50px;
  width: 33%;
  text-align: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: pointer;
}

.changeImageColorToActive {
  filter: invert(71%) sepia(7%) saturate(340%) hue-rotate(131deg) brightness(93%) contrast(82%);
}

img {
  height: inherit;
}

tbody {
  display: flex;
  overflow-x: scroll;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;

  margin-left: -40px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#root > div > div:nth-child(2) {
  z-index: 1;
}

input[name*="id["] {
  display: none;
}

input[name*="txt"] {
  display: none;
}

input[name="products_qty"] {
  display: none;
}

.bodyDekorSelect {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

#cart_quantity_mirror {
  display: grid;
  gap: 20px;
}

#cart_quantity_mirror > div:not(:has(#icon_ent))::before {
  content: "​​​  ";
  height: 24px;
}

#icon_ent::before {
  content: "​​​  ";
  height: 24px;
}

#cart_quantity_cabinet {
  display: grid;
  gap: 20px;
}

#cart_quantity_cabinet > div > div:not(:has(#icon_ent))::before {
  content: "​​​  ";
  height: 24px;
}

#turn {
  background-color: #0d646feb;
  color: #fff;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
}

/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media only screen and (orientation: landscape) {
  #turn {
    display: none;
  }
  .checkbox-group {
    flex-wrap: nowrap;
  }
  /* #masterContainerDiv{
    height: 100dvh !important;
    width: 100dvw !important;
  } */
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media only screen and (orientation: portrait) {
  #turn {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .mobileMenuHeightEdit {
    height: 60vh !important;
    bottom: 0 !important;
    top: unset !important;
  }

  .canvasHeightEdit {
    height: 40vh !important;
  }
}

@media screen and (max-width: 1300px) {
  .mobileMenuHeightEdit {
    padding: 12px !important;
  }
}

#buttonsContainer3 {
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0;
}

#buttonsContainer5 {
  position: absolute;
  z-index: 1;
  top: -50px;
  right: 0;
}

#buttonsContainer1 {
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
}

#buttonsContainer2 {
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0;
}

#buttonsContainer4 {
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0; 
}



